import React from 'react';
import {
  Typography,
  useTheme,
} from '@mui/material';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { parseAmountWithCurrency } from '../../../../../../../utils/helpers';
import { Currency } from '../../../../../../../types/account';

type AcceptedLoanDetailsProps ={
    requestLoanData:FormSchemaV3['requestLoanData'],
    currency: Currency
}

export default function AcceptedLoanDetails(props:AcceptedLoanDetailsProps) {
  const { requestLoanData, currency } = props;
  const theme = useTheme();

  const productName = () => {
    if (requestLoanData.periods < 2) {
      return 'adelanto';
    }
    return 'crédito';
  };

  return (
    <>
      <br />
      <Typography
        marginBottom={3}
        fontWeight="bold"
        variant="h6"
        gutterBottom
      >
        Este es el detalle del
        {' '}
        {productName()}
        {' '}
        que solicitarás

      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        margin: '5px auto',
        padding: 10,
        backgroundColor: theme.palette.background.defaultSecondary,
        borderRadius: '15px',
      }}
      >
        <div>
          <Typography color="text.primary">Monto total</Typography>
          <Typography
            color="secondary"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.amount,
              false,
              true,
            )}
          </Typography>
        </div>

        <div>
          <Typography color="text.primary">Plazo</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.periods}
            {' '}
            meses
          </Typography>
        </div>
        <div>
          <Typography color="text.primary">Costo de originación</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.originCost,
              false,
              true,
            )}
          </Typography>
        </div>
        <div>
          <Typography color="text.primary">
            Impuestos
            {+requestLoanData.insuranceCost > 0 ? ' y seguros' : ''}
          </Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.taxes + requestLoanData.insuranceCost,
            )}
          </Typography>
        </div>

        <div>
          <Typography color="text.primary">Tasa de interés</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.interestRate.toLocaleString(
              'es-CL',
              { style: 'percent', maximumFractionDigits: 2 },
            )}
          </Typography>
        </div>
        <div>
          <Typography color="text.primary">CAE aproximado</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.CAE.toLocaleString(
              'es-CL',
              { style: 'percent', maximumFractionDigits: 2 },
            )}
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '4.5%' }}>
        <div>
          <Typography
            fontWeight="bold"
            color="text.primary"
          >
            Monto cuota aproximada

          </Typography>
          <Typography
            fontWeight="bold"
            variant="h5"
            gutterBottom
            style={{ color: theme.palette.secondary.main }}
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.installment,
            )}
          </Typography>
        </div>
        <Typography
          color="text.primary"
          fontWeight="bold"
        >
          Costo total aproximado del
          {' '}
          {productName()}
        </Typography>
        <Typography
          fontWeight="bold"
          variant="h4"
          gutterBottom
          style={{ color: theme.palette.secondary.main }}
        >
          {parseAmountWithCurrency(
            currency,
            requestLoanData.totalCost,
          )}
        </Typography>
      </div>
    </>
  );
}

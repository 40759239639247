import {
  Button, Checkbox,
  Divider,
  FormControlLabel, List, ListItem,
  ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import { insuranceCalculator } from '../../utils/installmentCalculator';
import AlertDialog from '../AlertDialog';
import { getInsuranceLogo } from './helpers';
import { InsuranceType } from '../Simulator/types';
import { Currency } from '../../types/account';
import { parseAmountWithCurrency } from '../../utils/helpers';
import analytics from '../../utils/analytics';

type AvailableInsurancesType = {
    amount: number,
    periods: number,
    availableInsurances: InsuranceType[],
    handleSelectedAmountMarks: (mark: string) => void,
    checkedInsurances: {[key:InsuranceType['type']]: boolean},
    handleSetCheckedInsurances: (value: {[key:string]: boolean}) => void,
    showSimulator: boolean,
    hideFooter?: boolean,
    hideTitle?: boolean,
    iconSize?: 'small' | 'medium',
    readOnly?: boolean
    insuranceCost?: number
    currency: Currency,
    pageCall?: {
        name: string,
        category: string}
}

export default function InsurancesSelector(props:AvailableInsurancesType) {
  const {
    amount,
    periods,
    availableInsurances,
    handleSelectedAmountMarks,
    checkedInsurances,
    handleSetCheckedInsurances,
    showSimulator,
    hideFooter = false,
    hideTitle = false,
    iconSize = 'medium',
    readOnly = false,
    insuranceCost,
    currency,
    pageCall,
  } = props;

  const [originallyChecked, setOriginallyChecked] = React.useState({});
  const [initialChecked, setInitialChecked] = React.useState(checkedInsurances);

  React.useEffect(() => {
    setOriginallyChecked(checkedInsurances);
  }, []);

  const handleSubmitModifiedInsurances = () => {
    const selected = Object.entries(checkedInsurances).filter((entries) => entries[1]).length > 0
      ? Object.entries(checkedInsurances).filter((entries) => entries[1]).map((entries) => entries[0]).toString() : 'none';
    const selectedForAnalytics = Object.entries(checkedInsurances)
      .filter((entries) => entries[1]).length > 0
      ? Object.entries(checkedInsurances)
        .filter((entries) => entries[1]).map((entries) => entries[0]) : [];
    const originallyCheckedArray = Object.entries(originallyChecked)
      .filter((entries) => entries[1]).length > 0
      ? Object.entries(originallyChecked)
        .filter((entries) => entries[1]).map((entries) => entries[0]) : [];
    handleSelectedAmountMarks(selected);
    if (selectedForAnalytics.toString() !== originallyCheckedArray.toString()) {
      analytics.track('Click Boton', {
        initiator: 'usuario',
        text: 'Modificar',
        insurancesSelected: selectedForAnalytics,
      });
    }
    setInitialChecked(checkedInsurances);
  };

  const onCloseAction = () => {
    handleSetCheckedInsurances(initialChecked);
  };

  React.useEffect(() => {
    Object.keys((checkedInsurances)).forEach((key) => {
      const ins = availableInsurances.find((insurance) => insurance.type === key);
      if (ins?.maxPeriods && ins?.minPeriods) {
        if (periods < ins.minPeriods && periods > ins.maxPeriods) {
          handleSetCheckedInsurances({ ...checkedInsurances, [key]: false });
          setInitialChecked({ ...checkedInsurances, [key]: false });
        }
      }
    });
  }, [periods]);

  return (
    <div>
      {!hideTitle && (
      <Typography
        variant="body2"
        component="div"
        style={{ marginTop: 15 }}
        fontWeight="bold"
      >
        <u>
          Seguros asociados
        </u>
      </Typography>
      )}

      <div style={{
        display: 'flex', columnGap: 10, justifyContent: 'center', marginBlock: 10,
      }}
      >
        {Object.values(checkedInsurances).filter((value) => value).length > 0
          ? Object.entries(checkedInsurances).map(([key, value]) => (
            value
            && getInsuranceLogo({
              value: key,
              label: ((availableInsurances
                .find(
                  (insurance) => insurance.type === key,
                ) as InsuranceType).label),
              invertColors: true,
              iconSize,
            })
          ))
          : <Typography fontStyle="italic" color="GrayText" height={30}>(Ninguno)</Typography>}
      </div>

      {!hideFooter && (
        <Typography
          variant="caption"
          margin="auto"
          color="GrayText"
          maxWidth={250}
          component="div"
          marginBottom={1}
        >
          {!showSimulator ? 'Al sobreponer el cursor aparecerán los nombre de los seguros asociados'
            : `(+ ${parseAmountWithCurrency(currency, insuranceCost || 0)} )`}
        </Typography>
      )}
      <AlertDialog
        pageCall={pageCall}
        dialogTitle={readOnly ? 'Detalles' : 'Modificar seguros asociados'}
        submitButtonLabel="Modificar"
        closeAfterSubmit
        hideSubmitButton={readOnly}
        onCloseAction={onCloseAction}
        action={handleSubmitModifiedInsurances}
        button={showSimulator
          ? (
            <Button variant="outlined">
              {readOnly ? 'Ver detalles' : 'Modificar seguros asociados'}
            </Button>
          )
          : <div />}
        dialogContent={(
          <>
            <List
              style={{
                marginTop: 10, padding: 5, borderRadius: 15,
              }}
            >
              <ListItem
                secondaryAction={(
                  <Typography width={72} component="div">
                    Valor
                    {(currency.abbreviation === 'CLF' && (' (UF)'))}
                  </Typography>
)}
              >
                <ListItemText
                  primary={<Typography marginLeft={10} component="div">Concepto</Typography>}
                />
              </ListItem>
              <Divider />
              {Object.entries(checkedInsurances)
                .filter((entry) => {
                  const ins = availableInsurances.find((insurance) => insurance.type === entry[0]);
                  if (ins?.maxPeriods && ins?.minPeriods) {
                    if (periods < ins.minPeriods && periods > ins.maxPeriods) {
                      return false;
                    }
                  }
                  return true;
                })
                .map(([key, value]) => (
                  <div key={key}>
                    <ListItem
                      secondaryAction={(
                        <FormControlLabel
                          value="top"
                          control={(
                          readOnly ? <div style={{ width: 20, height: 20 }} /> : (
                            <Checkbox
                              size="small"
                              onClick={() => handleSetCheckedInsurances(
                                { ...checkedInsurances, [key]: !value },
                              )}
                              checked={value}
                            />
                          )
    )}
                          label={(
                            <Typography
                              variant="caption"
                              fontWeight="bold"
                              component="div"
                              paddingTop={1}
                            >
                              {availableInsurances && periods
                              && parseAmountWithCurrency(currency, insuranceCalculator({
                                amount,
                                periods,
                                precision: currency.precision,
                                insuranceRate: ((availableInsurances
                                  .find(
                                    (insurance) => insurance.type === key
                                    && (
                                      (!insurance.maxPeriods && !insurance.minPeriods)
                                        || (periods >= insurance.minPeriods
                                          && periods <= insurance.maxPeriods)),
                                  ) as InsuranceType).premiumMultiplier),
                                type: key,
                              }))}
                            </Typography>
    )}
                          labelPlacement="start"
                        />
            )}
                    >
                      <ListItemAvatar style={{ minWidth: 0, width: 40 }}>
                        {getInsuranceLogo({
                          value: key,
                          label: ((availableInsurances
                            .find(
                              (insurance) => insurance.type === key,
                            ) as InsuranceType).label),
                          iconSize: 'medium',
                        })}
                      </ListItemAvatar>
                      <ListItemText
                        style={{ width: 200 }}
                        primary={(
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            component="div"
                          >
                            {((availableInsurances
                              .find(
                                (insurance) => insurance.type === key,
                              ) as InsuranceType).label)}
                          </Typography>
)}
                        secondary={(
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{ maxWidth: { xs: 150, sm: 200 } }}
                            textAlign="justify"
                            color="GrayText"
                            fontStyle="italic"
                          >
                            {
                        ((availableInsurances
                          .find(
                            (insurance) => insurance.type === key,
                          ) as InsuranceType).description)
}
                          </Typography>
                      )}
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
            </List>
            <Typography variant="caption" component="div">
              El valor presentado es el valor
              que se agrega al monto total del crédito

            </Typography>
          </>
)}
      />
    </div>
  );
}

InsurancesSelector.defaultProps = {
  hideFooter: false,
  hideTitle: false,
  iconSize: 'medium',
  readOnly: false,
  insuranceCost: undefined,
  pageCall: undefined,
};

import React from 'react';
import { Button, Typography } from '@mui/material';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';

declare const window: any;

export const errorHandler = (
  error: Error,
  info: { componentStack: string },
  clientToken: string | undefined,
  user: any | undefined,
) => {
  window.newrelic.noticeError(
    error,
    {
      componentStack: info.componentStack,
      url: window.location.href,
      clientToken,
      user,
    },
  );
};

interface ErrorFallbackInterface {
    resetErrorBoundary: () => void;
}

export default function ErrorFallback(props: ErrorFallbackInterface) {
  const { resetErrorBoundary } = props;
  return (
    <div style={{
      display: 'flex', height: '100vh', justifyContent: 'center', textAlign: 'center', alignItems: 'center',
    }}
    >
      <div role="alert">
        <img src={isotipoDark} width="60px" alt="isotipo banca.me" />
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', marginTop: 10 }}
          gutterBottom
        >
          ¡Ha ocurrido un error inesperado!

        </Typography>
        <Typography variant="h6" style={{ margin: '10px 10%' }} gutterBottom>
          Estamos trabajando para poder resolver este problema lo antes posible.
        </Typography>
        <Typography variant="h6" style={{ margin: '10px 10%' }} gutterBottom>
          Por favor vuelve al inicio e inténtalo de nuevo.
        </Typography>
        <Button
          onClick={resetErrorBoundary}
          variant="contained"
          color="primary"
          style={{ maxWidth: '200px' }}
        >
          Volver al inicio
        </Button>
      </div>
    </div>
  );
}

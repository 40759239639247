import React from 'react';
import './styles.css';
import { Button } from '@mui/material';
import notFound from './404.svg';
import logo from '../../assets/bancame_logo_dark.svg';

function NotFound() {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const redirectToLanding = () => {
    window.location.href = 'https://banca.me';
  };

  return (
    <div className="products_body">
      <div>

        <object data={logo} className="mainLogo"> </object>
        <div className="cards_container">
          <div>
            <object
              data={notFound}
              aria-labelledby="notFound"
              className="notFound"
            />

          </div>
          <div>
            <h1>¡Nos perdimos!</h1>
            <h3>Esta página no existe</h3>
            <Button
              className="notFoundButton"
              onClick={redirectToLanding}
              color="primary"
              variant="contained"
            >
              Volver a Banca.Me
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default NotFound;

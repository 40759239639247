import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, useTheme } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import useStyles from './styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 20,
  boxShadow: `1px 1px 2px ${theme.palette.text.secondary}`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.defaultSecondary,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface CreditProgressInterface{
  paidInstallments: number,
  totalInstallments:number,
  totalAmount: number
}

export default function CreditProgressBar(props: CreditProgressInterface) {
  const { paidInstallments, totalInstallments, totalAmount } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', placeContent: 'center' }}>
        <Box sx={{ alignItems: 'center', width: '85%' }}>
          <BorderLinearProgress variant="determinate" value={(paidInstallments / totalInstallments) * 100} />
          <Box sx={{
            width: `${(paidInstallments / totalInstallments) * 100}%`,
            position: 'relative',
            marginTop: '-16px',
          }}
          >
            <div
              style={{ float: 'right', marginRight: 5 }}
              className={classes.dot}
            />
          </Box>

        </Box>
        <div
          style={{
            backgroundColor: theme.palette.secondary.main,
            position: 'relative',
            marginLeft: '-18px',
            marginTop: '4px',
          }}
          className={classes.dot}
        />
      </Box>
      <Typography
        style={{
          color: theme.palette.primary.main,
          marginTop: 20,
          marginBottom: -20,
          marginRight: '7%',
        }}
        align="right"
        fontWeight="bold"
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        $
        {' '}
        {totalAmount.toLocaleString('es-CL')}
      </Typography>
    </Box>

  );
}

import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

import accountStyles from '../styles';
import InvestmentOverview from '../../../feature/account/InvestmentOverview';
import { getInvestment } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import DataLoading from '../../../components/DataLoading';

export default function InvestmentView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;

  const { quoteBalance, quoteDataLoading, quoteDataError } = getInvestment(token);

  return (
    <Grid
      container
      style={{ height: '100%' }}
    >
      {quoteDataError
        && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
      {!quoteDataError && quoteDataLoading
          && (
            <DataLoading
              paddingTop="15%"
              firstText="Estamos consultando tu inversión.."
            />
          )}
      {!quoteDataError && !quoteDataLoading && quoteBalance
          && (
          <div className={classes.contentContainer}>
            <InvestmentOverview
              investment={quoteBalance.investment.balance}
            />
          </div>
          )}
    </Grid>
  );
}

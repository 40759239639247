import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useStyles, { sx } from './styles';

type BackButtonProps = {
    onClickAction: ()=> void,
    lightMode?: boolean
    label?: string|React.ReactElement,
    maxWidth?: number|string|undefined
}

export default function BackButton(props:BackButtonProps) {
  const {
    label, onClickAction, lightMode, maxWidth,
  } = props;
  const classes = useStyles();
  return (
    <div
      className={lightMode ? classes.rootLight : classes.root}
      style={{ maxWidth }}
    >
      <IconButton
        onClick={onClickAction}
        sx={lightMode ? sx().iconButtonLight : sx().iconButton}
      >

        <ArrowBackIcon sx={lightMode ? sx().arrowLight : sx().arrow} />
      </IconButton>
      <div
        aria-hidden
        onClick={onClickAction}
        style={{ marginLeft: 10 }}
      >
        {label}

      </div>
    </div>
  );
}
BackButton.defaultProps = {
  label: 'Volver',
  lightMode: false,
  maxWidth: undefined,
};

import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid, Typography, useTheme, Toolbar, Button,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { ReactSession } from 'react-client-session';
import bancameLogo from '../../assets/bancame_logo_dark.svg';
import useStyles from './styles';
import AlertDialog from '../AlertDialog';
import { useIsWidthDown } from '../../utils/widthHooks';
import jwtDecode from '../../utils/jwtDecode';
import {
  totalTabs,
  onlyInvestTabs,
  onlyLoanTabs,
  onlyProductsTab,
  StyledTab,
  StyledTabs,
  mobileIcons,
  logOut,
} from './helpers';
import analytics from '../../utils/analytics';
import { getElementFromStorage } from '../../utils/helpers';

export type AccountNavbarProps = {
  children: React.ReactElement<any, any>
}

export default function AccountNavbar(props:AccountNavbarProps) {
  const {
    children,
  } = props;
  const token = getElementFromStorage('token');
  const { hasLoan, hasInvestment } = jwtDecode(token);
  const {
    userId, idNumber, email, firstName, lastName,
  } = jwtDecode(ReactSession.get('token'));

  const location = useLocation();

  const [showButtons, setShowButtons] = React.useState(false);
  const [tab, setTab] = React.useState<'Tu crédito'|'Guia.me'|'Referidos'|'Inversiones'|'Pagos'>('Tu crédito');
  const accountNavbarTabSwitch = () => {
    if (location.pathname.includes('products')) {
      return 'Guia.me';
    }
    switch (location.pathname) {
      case '/account/referrals':
        return 'Referidos';
      case '/account/investment':
        return 'Inversiones';
      case '/account/pac':
        return 'Pagos';
      default:
        return 'Tu crédito';
    }
  };

  React.useEffect(() => {
    setTab(accountNavbarTabSwitch());
  }, [location.pathname]);

  React.useEffect(() => {
    setShowButtons(!!token);
  }, [token]);

  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();
  bootIntercom({
    name: `${firstName} ${lastName}`,
    email,
    userId,
    verticalPadding: 55,
    customAttributes: { idNumber },
  });

  function getShowableTabs() {
    if (hasInvestment && !hasLoan) {
      return onlyInvestTabs;
    }
    if (!hasInvestment && hasLoan) {
      return onlyLoanTabs;
    }
    if (!hasInvestment && !hasLoan) {
      return onlyProductsTab;
    }
    return totalTabs;
  }
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const isMdDown = useIsWidthDown('md');
  const showableTabs = getShowableTabs();
  const mobileTabs = showableTabs.filter((t) => t !== 'Pagos');
  const [value, setValue] = React.useState<number>(showableTabs.indexOf(tab));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== showableTabs.length - 1) {
      setValue(newValue);
    }
    if (value !== newValue) {
      if (showableTabs[newValue] === 'Guia.me') {
        analytics.track('Navegación', { boton: 'Guia.me' });
        return history.push('/account/products');
      }
      if (showableTabs[newValue] === 'Inversiones') {
        return history.push('/account/investment');
      }
      if (showableTabs[newValue] === 'Referidos') {
        return history.push('/account/referrals');
      }
      if (showableTabs[newValue] === 'Tu crédito') {
        return history.push('/account');
      }
      if (showableTabs[newValue] === 'Pagos') {
        return history.push('/account/pac');
      }
    }
    return undefined;
  };

  const payMentButtonActive = () => {
    if (window.location.href.includes('/account/pac')) {
      return 0;
    }
    return false;
  };

  React.useEffect(() => {
    if (!token) {
      shutdownIntercom();
    }
  }, [token]);

  return (
    <>
      <Grid
        xs={12}
        item
        className={classes.navBar}
        component={Toolbar}
      >

        <img
          src={bancameLogo}
          className={classes.logo}
          alt="Logo banca.me"
        />
        {(showButtons)
        && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledTabs
              value={isMdDown ? payMentButtonActive() : value}
              onChange={isMdDown ? () => history.push('/account/pac') : handleChange}
              aria-label="styled tabs example"
              sx={{ display: { xs: 'block' } }}
            >
              {!isMdDown && hasLoan && (
                <StyledTab label="Tu crédito" />
              )}
              {!isMdDown && hasLoan && (
                <StyledTab label="Referidos" />
              )}
              {!isMdDown && hasInvestment && (
                <StyledTab label="Inversiones" />
              )}
              {!isMdDown
              && <StyledTab label="Guia.me" />}
              {showableTabs.includes('Pagos') && (
              <StyledTab label="Pagos" />
              )}
            </StyledTabs>
            {!isMdDown
            && (
            <AlertDialog
              dialogTitle={(
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  component="div"
                  align="center"
                >
                  ¿Cerrar sesión?
                </Typography>
)}
              dialogContent="Si presiona continuar, se redirigirá a la vista de inicio de sesión"
              action={() => logOut()}
              button={(
                <Button
                  style={{ maxWidth: 100 }}
                  variant="outlined"
                >
                  Salir

                </Button>
                    )}
              pageCall={{ category: 'PU - LOGOUT', name: 'CONFIRMAR LOGOUT' }}
            />
            )}
          </div>
        )}
      </Grid>
      <div className={classes.children}>
        {children}
        {isMdDown && showButtons && <div style={{ height: 100 }} />}
      </div>
      {isMdDown && showButtons
        && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}
          className={classes.mobileNavigation}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${mobileTabs.length}, 1fr)`,
            placeItems: 'center',
          }}
        >
          {mobileTabs.slice(0, mobileTabs.length - 1).map((mobileTab) => (
            <BottomNavigationAction
              key={mobileTab}
              className={classes.mobileBottomNavBarAction}
              label={<Typography variant="caption">{mobileTab}</Typography>}
              icon={mobileIcons(mobileTabs[value])[mobileTab]}
            />
          ))}

          <AlertDialog
            dialogTitle={(
              <Typography
                fontWeight="bold"
                variant="h6"
                component="div"
                align="center"
              >
                ¿Cerrar sesión?
              </Typography>
)}
            dialogContent="Si presiona continuar, se redirigirá a la vista de inicio de sesión"
            action={() => logOut()}
            button={(
              <BottomNavigationAction
                className={classes.mobileBottomNavBarAction}
                style={{ paddingTop: '8px' }}
                icon={(
                  <>
                    <LogoutIcon style={{
                      color: showableTabs[value] === 'Cerrar sesión'
                        ? theme.palette.secondary.main
                        : undefined,
                      paddingBottom: '10px',
                      fontSize: '22px',
                    }}
                    />
                    <Typography noWrap variant="caption">Cerrar sesión</Typography>
                  </>
                )}
              />
              )}
            pageCall={{ category: 'PU - LOGOUT', name: 'CONFIRMAR LOGOUT' }}
          />
        </BottomNavigation>
        )}
    </>
  );
}

import { createTheme, responsiveFontSizes } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InterRegular from './fonts/Inter/Inter-Regular.woff2';
import InterBold from './fonts/Inter/Inter-Bold.woff2';
import InterLight from './fonts/Inter/Inter-Light.woff2';

const inter = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Inter'),
    local('Inter-Regular'),
    url(${InterRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const interBold = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Inter'),
    local('Inter-Bold'),
    url(${InterBold}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const interLight = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Inter'),
    local('Inter-Light'),
    url(${InterLight}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    tertiary: {
      main: string,
    },
    white: {
      main: string
    },
    gray: {
    main: string
    },
    black:{
    main: string,
    }
  }

 export interface TypeBackground {
    defaultSecondary: string,
    default: string,
    dark: string,
    darkSecondary: string,
  }
  export interface PaletteOptions {
    tertiary: {
      main: string,
    },
    white: {
      main: string
    },
    gray: {
      main: string
    },
    black: {
        main: string,
    }
  }
}

const muiTheme = createTheme({
  spacing: 4,
  palette: {
    background: {
      dark: '#755DDF',
      darkSecondary: '#332675',
      defaultSecondary: '#F2F2F2',
      default: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },
    gray: {
      main: '#F2F2F2',
    },
    primary: {
      main: '#332675',
    },
    secondary: {
      main: '#755DDF',
    },
    tertiary: {
      main: '#99FF99',
    },
    white: {
      main: '#FFFFFF',
    },

    text: {
      primary: '#000000',
      secondary: '#332675',
    },
    success: {
      main: '#F2F2F2',
    },
    error: {
      main: '#C0392B',
    },
    info: {
      main: '#332675',
    },
    warning: {
      main: '#FFC107',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [inter, interBold, interLight],
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 45,
          width: 225,
          textTransform: 'none',
          fontWeight: 'bold',
          borderRadius: 10,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: '#755DDF',
          '&:hover': {
            backgroundColor: '#332675',
          },
          '&:focus': {
            backgroundColor: '#332675',
          },
        },
        containedSecondary: {
          backgroundColor: '#99FF99',
          color: '#000000',
          '&:hover': {
            color: '#755DDF',
            backgroundColor: '#99FF99',
          },
          '&:focus': {
            color: '#755DDF',
            backgroundColor: '#99FF99',
          },
        },
        outlinedPrimary: {
          borderWidth: '1.5px',
          backgroundColor: '#FFFFFF',
          color: '#000000',
          borderColor: '#000000',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            borderColor: '#755DDF',
            color: '#755DDF',
          },
          '&:focus': {
            backgroundColor: '#FFFFFF',
            borderColor: '#755DDF',
            color: '#755DDF',
          },
          '&:active': {
            backgroundColor: '#FFFFFF',
            borderColor: '#755DDF',
            color: '#755DDF',
          },
        },
        outlinedSecondary: {
          borderWidth: '1px',
          backgroundColor: '#FFFFFF',
          color: '#755DDF',
          borderColor: '#755DDF',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            borderColor: '#332675',
            color: '#332675',
          },
          '&:focus': {
            backgroundColor: '#FFFFFF',
            borderColor: '#332675',
            color: '#332675',
          },
          '&:active': {
            backgroundColor: '#FFFFFF',
            borderColor: '#332675',
            color: '#332675',
          },
        },
        textPrimary: {
          color: '#000000',
          width: 0,
        },
        textSecondary: {
          color: '#99FF99',
          width: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000000',
          lineHeight: 1.1,
        },
        h1: {
          fontSize: '34px',
        },
        h2: {
          fontSize: '27px',
        },
        h3: {
          fontSize: '22px',
        },
        h4: {
          fontSize: '18px',
        },
        // subtitle1: {
        //   fontSize: '1.2rem',
        //   lineHeight: 1.1,
        // },
        // subtitle2: {
        //   color: '#7F8EB3',
        // },
        body1: {
          lineHeight: 1.3,
        },
        body2: {
          lineHeight: 1.3,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&::placeholder': {
            color: '#000000',
          },
          color: '#000',
          backgroundColor: 'white',
          borderRadius: '7px',
          borderColor: '#000000',
          '& label': {
            color: '#35AAC5',
          },
          '& label.Mui-focused': {
            color: '#35AAC5',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#F2F2F2',
          height: 55,
          borderRadius: 10,
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'orange',
            },
          },
        },
        notchedOutline: { borderWidth: '0px' },

      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
        underline: {
          borderColor: '#755DDF',
          '&:before': {
            borderColor: '#755DDF !important',
          },
          '&:after': {
            borderColor: '#332675',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderColor: '#332675',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: '0 ',
          },
        },
        rounded: {
          borderRadius: '20px',
          borderTop: 'none',
          color: '#000000',
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&$expanded': {
            margin: 0,
          },
        },
        content: {
          margin: '10px 20px',
          '&.Mui-expanded': {
            margin: '0 20px',
          },
        },
        expandIconWrapper: {
          margin: '0 !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '&::placeholder': {
            color: '#000000',
          },
          '& label': {
            color: '#35AAC5',
            transform: 'translate(14px, 12px) scale(1)',
          },
          '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#000000',
          height: '1.3em',
          width: '1em',
        },
        colorSecondary: {
          color: 'rgb(200, 203, 207)',
          height: '1.3em',
          width: '1em',
        },
        colorPrimary: {
          color: 'white',
          backgroundColor: '#000000',
          height: '1.3em',
          width: '1em',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          alignSelf: 'center',
          // width: '16% !important',
          // height: '16% !important',
          // position: 'relative',
          // left: '42%',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        mark: {
          width: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
        standardError: {
          color: '#FFFFFF',
          backgroundColor: '#C0392B',
        },
        standardSuccess: {
          backgroundColor: '#FFFFFF',
        },
        standardInfo: {
          color: '#FFFFFF',
          backgroundColor: '#755DDF',
        },
        standardWarning: {
          color: '#000000',
          backgroundColor: '#FFC107',
        },
      },
    },
  },

});

const theme = responsiveFontSizes(muiTheme);

// shared styles
const useSharedStyles = makeStyles({
  mobileSecondaryBackground: {
    backgroundColor: theme.palette.background.defaultSecondary,
    minHeight: '100vh',
    padding: '10px',
  },
  form_field: {
    width: '80%',
    margin: '0 10%',
  },
  link_no_decoration: {
    textDecoration: 'none',
    color: 'inherit',
  },
  underline: {
    textDecoration: 'underline',
  },
  textInputError: {
    margin: '0 !important',
    marginTop: '10px !important',
  },
});

export { theme, useSharedStyles };

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Partner } from '../LeadFormIndex/types';

interface CustomButtonProps extends ButtonProps {
  primarycolor: string;
  secondarycolor: string | undefined;
}

function isValidCssColor(color: string): boolean {
  const s = new Option().style;
  s.color = color;
  return s.color !== '';
}

const PartnerStyledButton = styled(Button)<CustomButtonProps>(({
  primarycolor,
  secondarycolor,
}) => ({
  color: 'secondary',
  border: `2px solid ${primarycolor}`,
  background: secondarycolor || 'white',
  boxShadow: `0px 3px 1px -2px ${primarycolor}, 0px 2px 2px 0px ${primarycolor}, 0px 1px 5px 0px ${primarycolor}`,
  '&:hover': {
    backgroundColor: primarycolor,
    color: secondarycolor || 'white',
  },
  '&:focus': {
    color: primarycolor,
    background: secondarycolor || 'white',
  },
}));

export default function getPartnerButton(params: {
  partner: Partner,
  step?:number,
  className?: string,
  testingLabel: 'preloan-welcome-button'|'preloan-submit-button'| 'signature-submit-button',
  onClick: ()=> void,
  buttonLabel: string,
  disabled?: boolean,
  style?: {},
  endIcon?: React.ReactElement<any, any>}) {
  const {
    partner,
    step = 0,
    className = undefined,
    testingLabel,
    onClick,
    buttonLabel,
    disabled = false,
    style = undefined,
    endIcon = undefined,
  } = params;
  if (step === -1 && partner.partnerName === 'Banca.Me') {
    return undefined;
  }
  const componentProps = {
    className,
    'data-pw': testingLabel,
    variant: 'contained' as ButtonProps['variant'],
    color: 'primary' as ButtonProps['color'],
    onClick,
    disabled,
    style,
    endIcon,
  };
  if (partner.partnerConfiguration.primaryColor !== undefined
    && isValidCssColor(partner.partnerConfiguration.primaryColor)) {
    return (
      <PartnerStyledButton
        {...{
          ...componentProps,
          primarycolor: partner.partnerConfiguration.primaryColor,
          secondarycolor: partner.partnerConfiguration.secondaryColor,
        }}
      >
        {buttonLabel}
      </PartnerStyledButton>
    );
  }
  return (
    <Button {...componentProps}>
      {buttonLabel}
    </Button>
  );
}

import React from 'react';
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Button,
  Alert,
  Fade,
  Snackbar,
  Divider,
} from '@mui/material';
import { verifyEmail } from '../../utils/helpers';
import { postRecoverPassword } from '../../queries/account';
import bancameLogo from '../../assets/bancame_logo_dark.svg';
import analytics from '../../utils/analytics';

export default function RecoverPasswordForm() {
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);
  const [sentEmail, setSentEmail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(0);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertMessage('');
  };

  const sendMail = async () => {
    setError(false);
    setLoading(true);
    setSentEmail(false);

    if (!verifyEmail(email)) {
      setError(true);
    } else {
      try {
        await postRecoverPassword(email);
        setSentEmail(true);
        setAlertMessage('Correo enviado, revisa tu email.');
        setTimeLeft(15);
      } catch (e) {
        setSentEmail(false);
        setAlertMessage('El email no se encuentra en nuestra base de datos');
      }
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [timeLeft]);

  React.useEffect(() => {
    analytics.page('PU - LOGIN', 'RECUPERAR CUENTA');
  }, []);

  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <Grid
        item
        xs={12}
        lg={5}
        md={6}
        sm={10}
        component={Paper}
        elevation={4}
        borderRadius={5}
        padding={10}
      >
        <div>
          <div style={{ marginBottom: 10, textAlign: 'center' }}>
            <object
              data={bancameLogo}
              width="140px"
              aria-labelledby="banca.me logo"
            />
          </div>
          <Divider />
          <br />
          <Typography variant="h5" component="h3" align="center">
            <b>Recuperar cuenta</b>
          </Typography>
          <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
            Ingresa tu email. Luego, te llegará un correo para que puedas continuar el proceso.
          </Typography>
          <TextField
            style={{ marginBlock: 20 }}
            value={email}
            placeholder="Ingresa tu email"
            error={error}
            helperText={error ? 'Por favor ingrese email válido' : ''}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading || timeLeft > 0}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={sendMail}
            disabled={loading || timeLeft > 0 || !verifyEmail(email)}
          >
            {timeLeft > 0 ? `Reintento en ${timeLeft} segundos...` : 'Confirmar'}
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={!!alertMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert severity={sentEmail ? 'success' : 'error'}>{alertMessage}</Alert>
          </Snackbar>

        </div>
      </Grid>
    </Fade>
  );
}

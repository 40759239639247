import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import BancameAlert from '../Alert';
import bancoBice from './enums';
import useStyles from './styles';
import AlertDialog from '../AlertDialog';
import analytics from '../../utils/analytics';

interface TransferDetailsDialogInterface{
  hasDelinquency: boolean,
  unpaidDelinquencyAmount: number,
  unpaidInstallmentAmount: number,
  paymentMethod: string,
}

function TransferDetailsDialog(props:TransferDetailsDialogInterface) {
  const {
    paymentMethod,
    hasDelinquency,
    unpaidDelinquencyAmount,
    unpaidInstallmentAmount,
  } = props;
  const totalToDeposit = unpaidInstallmentAmount + unpaidDelinquencyAmount;
  const classes = useStyles();
  const [isTriggered, setIsTriggered] = React.useState(false);
  const [triggerClose, setTriggerClose] = React.useState(false);
  const theme = useTheme();

  const handleClose = () => {
    setTriggerClose(true);
  };

  const copyTextToClipboard = () => {
    analytics.track('Click Boton', {
      text: 'Copiar',
      initiator: 'usuario',
    });
    navigator.clipboard.writeText(
      `Bancame Trust Spa
      77.673.115-3
      Banco BICE
      Cuenta Corriente
      01-38366-3
      info@banca.me`,
    );
  };

  const handleOpenWhatsApp = () => {
    analytics.track('Click Boton', {
      text: 'Listo, yá transferí',
      initiator: 'usuario',
    });
    window.open(
      `https://api.whatsapp.com/send/?phone=56920711980&text=¡Hola!+Hice+una+trasferencia+por+el+monto+total+del+prepago+por+$${hasDelinquency}.`,
    );
  };
  const isDPPDelinquent = hasDelinquency && paymentMethod !== 'dpp';
  React.useEffect(() => {
    if (isDPPDelinquent) {
      setIsTriggered(true);
    }
  }, []);

  return (
    <AlertDialog
      pageCall={{
        category: isDPPDelinquent ? 'PU - LOGIN' : 'PU - TU CREDITO',
        name: isDPPDelinquent ? 'DEUDA PENDIENTE' : 'DATOS DE PAGO',
      }}
      maxWidth="lg"
      triggerClose={triggerClose}
      isTriggered={isTriggered}
      button={(
        <Button
          variant="outlined"
          color="primary"
        >
          Datos de pago
        </Button>
)}
      dialogTitle={(!hasDelinquency) && (
        <DialogTitle
          style={{ textAlign: 'left' }}
        >
          Datos para la transferencia
        </DialogTitle>
      )}
      hideSubmitButton
      dialogContent={(
        <DialogContent>
          {hasDelinquency && paymentMethod === 'pac'
          && (
          <div>
            <Typography
              variant="h6"
              fontWeight="bold"
              align="center"
              gutterBottom
            >
              Tienes una deuda pendiente
            </Typography>
            <Typography
              variant="body2"
              align="center"
              gutterBottom
            >
              Debes hacer una transferencia para ponerte al día
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              align="center"
              gutterBottom
            >
              Desglose de pagos pendientes
            </Typography>
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                + Monto de cuotas pendientes
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {unpaidInstallmentAmount.toLocaleString()}
              </Typography>
            </div>
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                + Intereses devengados
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {unpaidDelinquencyAmount.toLocaleString()}
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="text.secondary"
                display="inline"
              >
                Total
              </Typography>
              <Typography
                variant="body2"
                align="center"
                gutterBottom
                color="secondary"
                fontWeight="bold"
                display="inline"
              >
                {' $ '}
                {totalToDeposit.toLocaleString()}
              </Typography>
            </div>
            <br />
          </div>
          )}
          <Typography
            variant="body1"
            fontWeight="bold"
            align="left"
            gutterBottom
          >
            Datos bancarios
          </Typography>
          <Grid
            container
            sx={{
              alignItems: 'center',
              borderRadius: '15px',
            }}
            justifyContent="center"
          >

            <Grid
              item
              xs={12}
            >

              <Typography
                variant="body1"
                align="left"
                gutterBottom
              >
                {bancoBice.name.value}
              </Typography>
              <Divider />
              {Object.keys(bancoBice).map((key) => (
                key !== 'name'
                && (
                  <div key={key} style={{ display: 'flex', placeContent: 'space-between' }}>
                    <Typography
                      variant="body2"
                      align="left"
                    >
                      {bancoBice[key].label}
                      :
                    </Typography>
                    <Typography
                      variant="body2"
                      align="right"
                      fontWeight="bold"
                    >
                      {bancoBice[key].value}
                    </Typography>
                  </div>
                )
              ))}
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <BancameAlert
                button={(
                  <IconButton
                    onClick={copyTextToClipboard}
                    size="large"
                  >
                    <Tooltip
                      title="Copiar datos"
                    >
                      <FileCopyOutlinedIcon style={{ color: theme.palette.grey[600], fontSize: '40px' }} />
                    </Tooltip>
                  </IconButton>
)}
                successMessage="Has copiado los datos con éxito"
                errorMessage="Ha ocurrido un error copiando el texto"
                action={copyTextToClipboard}
              />

            </Grid>
          </Grid>
          {hasDelinquency
            && (
              <div className={classes.actionButtons}>
                <Button variant="contained" onClick={handleOpenWhatsApp}>
                  Listo, ya transferí
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                >
                  Recuérdamelo más tarde

                </Button>
              </div>
            )}
        </DialogContent>
      )}
      hideCloseButton={hasDelinquency}
    />
  );
}

export default TransferDetailsDialog;

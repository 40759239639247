import React from 'react';
import {
  Grid,
} from '@mui/material';

import accountStyles from '../styles';
import { getUserReports } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import AccountDataLoading from '../../../components/DataLoading';
import ProductsList from '../../../feature/account/ProductsList';

export default function ProductsView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;

  const { userReportsData, userReportsDataLoading, userReportsDataError } = getUserReports(token);

  return (
    <Grid
      container
      style={{ height: '100%' }}
    >
      {(userReportsDataError || userReportsDataLoading)
          && (
            <AccountDataLoading
              paddingTop="15%"
              firstText="Estamos consultando tus productos..."
              error={!userReportsDataLoading && userReportsDataError}
            />
          )}
      {!userReportsDataError && !userReportsDataLoading && userReportsData
          && (
            <div className={classes.contentContainer} style={{ width: '100%' }}>
              <ProductsList
                userReportsData={userReportsData}
                token={token}
              />
            </div>
          )}
    </Grid>
  );
}

import React from 'react';
import { Formik, Form } from 'formik';
import { CircularProgress, Typography } from '@mui/material';
import type { PLRFormProps } from '../../../../../../types/preloan';
import {
  postClaveUnicaHistory,
  patchClaveUnica,
  postPreApprove,
  patchDependentStatus,
} from '../../../../../../queries/preloan/v3';
import {
  FormSchemaV3,
  PatchCheckDocumentsResponse,
  PostClaveUnicaResponse,
} from '../../../../../../types/preloan/v3';
import ClaveUnicaRequest from './components/ClaveUnicaRequest';
import { getInterestRate } from '../../../../../../queries/preloan';
import { ClaveUnicaEndpointProps } from '../../../../../../components/ClaveUnicaForm';
import DependentStatusDialog, { WorkerType } from '../../../../DependentStatusDialog';
import { nonAutomaticallyRejectedOrigins, partnerErrorRedirectURL, preLoanRequestStep } from '../../formLogic';
import { returnDataForm } from '../02_dataFetching/components/helpers';
import analytics from '../../../../../../utils/analytics';

export default function ClaveUnicaForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleStep,
    handleCurrentData,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [triggerDependentStatusDialog, setTriggerDependentStatusDialog] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(
        formSchema.origin,
        formSchema.rut,
        formSchema.partnerConfigurations,
      );
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  const claveUnicaSuccess = async (
    response: PostClaveUnicaResponse,
  ) => {
    const { data } = response;
    if (response.status === 206) {
      analytics.page('PLR - SITUACIÓN LABORAL', 'ELECCION TIPO EMPLEADO');
      return setTriggerDependentStatusDialog(true);
    }
    switch (data.plr.step) {
      case 'DATA_OK':
        setLoading(true);
        try {
          const { data: preApproveData } = await postPreApprove(formSchema.PLRtoken);
          switch (preApproveData.plr.step) {
            case 'LOAN_SIMULATION': {
              const interestRate = await getInterestRate(formSchema.PLRtoken);
              handleCurrentData({
                ...formSchema,
                step: preApproveData.plr.step,
                loanParams: interestRate.data,
              });
              return handleStep(preLoanRequestStep.LOAN_SIMULATION);
            }
            case 'READY_FOR_LOAN': {
              handleCurrentData({
                ...formSchema,
                step: preApproveData.plr.step,
                preApprove: {
                  accepted: preApproveData.preApprove?.accepted,
                  maxAmount: preApproveData.preApprove?.maxAmount,
                  productType: preApproveData.preApprove?.productType,
                  rate: preApproveData.preApprove?.rate,
                  maxPeriods: preApproveData.preApprove?.maxPeriods,
                },
              });
              return handleStep(preLoanRequestStep.READY_FOR_LOAN);
            }
            case 'ARCHIVED_FAIL':
              handleCurrentData({
                ...formSchema,
                step: 'ARCHIVED_FAIL',
                firstName: preApproveData.plr.firstName,
                lastName: preApproveData.plr.lastName,
                rut: preApproveData.plr.idNumber,
                results: preApproveData.results,
                origin: preApproveData.plr.origin,
              });
              return handleStep(nonAutomaticallyRejectedOrigins
                .includes(formSchema.origin) ? preLoanRequestStep.READY_FOR_LOAN
                : preLoanRequestStep.ARCHIVED_FAIL);
            default:
              return setError(true);
          }
        } catch (e) {
          return setError(true);
        }
      case 'UPLOAD_DOCUMENTS':
        handleCurrentData({
          ...formSchema,
          step: data.plr.step,
          needsPensionStatement: data.plr.needsPensionStatement,
          needsSettlement: data.plr.needsSettlement,
          settlementsRequired: data.settlementsRequired,
          pensionStatementsRequired: data.pensionStatementsRequired,
        });
        return handleStep(preLoanRequestStep.UPLOAD_DOCUMENTS);
      case 'ARCHIVED_FAIL': {
        handleCurrentData({
          ...formSchema,
          adminComment: data.plr.adminComment,
          firstName: data.plr.firstName,
          lastName: data.plr.lastName,
          rut: data.plr.idNumber,
          origin: data.plr.origin,
        });
        return handleStep(nonAutomaticallyRejectedOrigins
          .includes(formSchema.origin)
          ? preLoanRequestStep.READY_FOR_LOAN
          : preLoanRequestStep.ARCHIVED_FAIL);
      }
      case 'FILL_FORM': {
        const newData = data as PatchCheckDocumentsResponse['data'];
        const rawDataForm = newData.form;
        const { dataForm, contactForm } = returnDataForm({
          rawDataForm,
          phone: data.plr.phone,
          email: data.plr.email,
          formSchema,
        });
        handleCurrentData({
          ...formSchema,
          firstName: data.plr.firstName,
          lastName: data.plr.lastName,
          step: data.plr.step,
          dataForm,
          contactForm,
        });
        return handleStep(preLoanRequestStep.FILL_FORM);
      }
      default:
        return setError(true);
    }
  };

  const postAndPatchClaveUnica = async (params: ClaveUnicaEndpointProps) => {
    const { token, password } = params;
    await postClaveUnicaHistory({ token, password });
    return patchClaveUnica({ token });
  };

  const patchDependentStatusAction = async (workerType: WorkerType) => {
    const res = await patchDependentStatus({ token: formSchema.PLRtoken, workerType });
    const { data } = res;
    switch (res.data.plr.step) {
      case 'UPLOAD_DOCUMENTS':
        handleCurrentData({
          ...formSchema,
          step: data.plr.step,
          needsPensionStatement: data.plr.needsPensionStatement,
          needsSettlement: data.plr.needsSettlement,
          settlementsRequired: data.settlementsRequired,
          pensionStatementsRequired: data.pensionStatementsRequired,
        });
        return handleStep(preLoanRequestStep.UPLOAD_DOCUMENTS);
      case 'NEEDS_ICP':
        handleCurrentData({
          ...formSchema,
          step: data.plr.step,
          needsPensionStatement: data.plr.needsPensionStatement,
          needsSettlement: data.plr.needsSettlement,
        });
        return handleStep(preLoanRequestStep.NEEDS_ICP);
      case 'ARCHIVED_FAIL': {
        const newData = data;
        handleCurrentData({
          ...formSchema,
          adminComment: newData.plr.adminComment,
          firstName: newData.plr.firstName,
          lastName: newData.plr.lastName,
          rut: newData.plr.idNumber,
          origin: newData.plr.origin,
          isRedirect: 'SITUACION LABORAL',
          results: newData.results,
        });
        return handleStep(nonAutomaticallyRejectedOrigins
          .includes(formSchema.origin)
          ? preLoanRequestStep.READY_FOR_LOAN
          : preLoanRequestStep.ARCHIVED_FAIL);
      }
      case 'FILL_FORM': {
        const newData = data;
        const rawDataForm = newData.form;
        const dataForm = {} as FormSchemaV3['dataForm'];
        const contactForm = {} as FormSchemaV3['contactForm'];
        Object.keys(rawDataForm).forEach((key) => {
          let value: string;
          if (key === 'phone') {
            value = data.plr.phone ? `+56 ${data.plr.phone}` : '+56 ';
            contactForm[key] = { value, type: rawDataForm[key], ok: true };
          } else if (key === 'email') {
            value = data.plr.email || '';
            contactForm[key] = { value, type: rawDataForm[key], ok: true };
          } else {
            value = formSchema.dataForm[key].value;
            dataForm[key] = { value, type: rawDataForm[key] };
          }
        });
        handleCurrentData({
          ...formSchema,
          firstName: data.plr.firstName,
          lastName: data.plr.lastName,
          step: data.plr.step,
          dataForm,
          contactForm,
        });
        return handleStep(preLoanRequestStep.FILL_FORM);
      }
      default:
        return setError(true);
    }
  };
  React.useEffect(() => {
    setTimeout(() => setTriggerDependentStatusDialog(false), 3000);
  }, [triggerDependentStatusDialog]);

  React.useEffect(() => {
    analytics.page('PLR - ONBOARDING', 'INGRESO CU');
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={() => { }}
    >
      {({
        values,
      }) => (

        <Form>
          {loading ? (
            <>
              <CircularProgress />
              <Typography variant="h6">
                Estamos consultado tus datos, por favor espera un momento...
              </Typography>
              {error && (
              <Typography variant="caption" color="error">
                Ha ocurrido un error, por favor intente en otra ocasión o
                contáctanos
                al servicio al cliente.
              </Typography>
              )}
            </>
          )
            : (
              <>
                <br />
                <ClaveUnicaRequest<PostClaveUnicaResponse>
                  formSchema={values}
                  postClaveUnicaApprove={postAndPatchClaveUnica}
                  claveUnicaSuccess={claveUnicaSuccess}
                />
              </>
            )}
          <DependentStatusDialog
            action={patchDependentStatusAction}
            isTriggered={triggerDependentStatusDialog}
            formSchema={values}
            handleCurrentData={handleCurrentData}
            handleStep={handleStep}
          />
        </Form>
      )}
    </Formik>
  );
}

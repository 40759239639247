import React from 'react';
import {
  Card, Tooltip, Typography, useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useStyles from './styles';

type FinancialIndexesProps ={
    totalDebt: number,
    totalDelinquency: number,
    financialBurden: number | null,
    monthlyDebts: number,
    pdf: boolean,
}

export default function FinancialIndexes(props:FinancialIndexesProps) {
  const {
    totalDebt, totalDelinquency, financialBurden, monthlyDebts, pdf,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Typography gutterBottom variant="h6" display="flex" alignItems="center">
        Mis indicadores financieros
        {' '}
        {!pdf && (
          <Tooltip
            title={(
              <div style={{ padding: 5 }}>
                <b>Mis indicadores financieros</b>
                <div>
                  Este resumen representa un compilado y análsis de tus finanzas.
                  La deuda total comprende el total de créditos
                  y deudas que registras en el sistema.
                  Tu mora actual es el consolidado de deudas morosas en el sistema.
                  Tu carga financiera representa la proporción entre tus deudas y tu salario.

                </div>
              </div>
)}
            arrow
            componentsProps={{ tooltip: { style: { background: 'white', color: theme.palette.primary.main } } }}
          >
            <InfoOutlinedIcon style={{ fontSize: '20px', marginLeft: 5 }} />
          </Tooltip>
        )}
      </Typography>
      <Typography
        gutterBottom
        variant="caption"
        color="primary"
        component="div"
      >
        Un breve resumen de cómo se ven tus finanzas

      </Typography>
      <div className={classes.indexCards}>
        <Card style={{ textAlign: 'center', padding: 15, borderRadius: 10 }}>
          <Typography
            align="center"
            gutterBottom
          >
            Tu deuda total
          </Typography>
          <Typography
            align="center"
            variant={pdf ? 'h5' : 'h4'}
            color="primary"
            fontWeight="bold"
            height={pdf ? 50 : undefined}
          >
            $
            {' '}
            {totalDebt.toLocaleString('es-CL')}
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            color="text.secondary"
          >
            Es el total que debes entre créditos de consumo, líneas y tarjetas de crédito.

          </Typography>
        </Card>
        <Card style={{ textAlign: 'center', padding: 15, borderRadius: 10 }}>
          <Typography
            align="center"
            gutterBottom
          >
            Tu mora actual
          </Typography>
          <Typography
            align="center"
            variant="h4"
            style={{ color: theme.palette.secondary.main }}
            fontWeight="bold"
            height={pdf ? 50 : undefined}
          >
            $
            {totalDelinquency.toLocaleString('es-CL')}
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            color="text.secondary"
            component="div"
          >
            Es el total de las deudas que no pagaste a tiempo y se transformó en mora

          </Typography>
        </Card>
        {
         financialBurden && (
         <Card style={{ textAlign: 'center', padding: 15, borderRadius: 10 }}>
           <Typography
             align="center"
             gutterBottom
           >
             Tu carga financiera
           </Typography>
           <Typography
             align="center"
             variant="h4"
             color="primary"
             fontWeight="bold"
             height={pdf ? 50 : undefined}
           >
             {financialBurden.toLocaleString('es-CL', { style: 'percent' })}
           </Typography>
           <Typography
             gutterBottom
             variant="caption"
             color="text.secondary"
             component="div"
           >
             Aproximadamente es lo que estás pagando mensualmente de tus ingresos en deudas

           </Typography>
         </Card>
         )
        }
        {
          financialBurden === null && (
          <Card style={{ textAlign: 'center', padding: 15, borderRadius: 10 }}>
            <Typography
              align="center"
              gutterBottom
            >
              Tu gasto en deudas
            </Typography>
            <Typography
              align="center"
              variant="h4"
              color="secondary"
              fontWeight="bold"
            >
              $
              {monthlyDebts.toLocaleString('es-CL')}
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              color="text.secondary"
              component="div"
            >
              Aproximadamente es lo que estás pagando mensualmente en deudas
            </Typography>
          </Card>
          )
        }
      </div>
    </>
  );
}

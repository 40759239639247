import * as React from 'react';
import {
  Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Select, Typography, MenuItem,
} from '@mui/material';
import {
  Formik, Form, FormikTouched, FormikErrors,
} from 'formik';
import AlertDialog from '../../../components/AlertDialog';
import zubaleLogo from '../../../assets/zubale_logo_blue.webp';
import zubaleLogoMinimal from '../../../assets/zubale_logo_minimal.webp';
import useStyles from './styles';
import type { FormSchemaV3 } from '../../../types/preloan/v3';
import zubaleUserFormValidationSchema from './validationSchema';
import { zubaleFormInputs } from './inputsData';
import TermsAndConditionsDialog from './termsAndConditions';

type ZubaleUserInputFormProps = {
  formSchema: FormSchemaV3,
  onSubmit: (userInput: any) => void,
}

export default function ZubaleUserInputForm(props: ZubaleUserInputFormProps) {
  const {
    formSchema,
    onSubmit,
  } = props;
  const classes = useStyles();
  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const [submitOk, setSubmitOk] = React.useState(false);
  const [triggerDialog, setTriggerDialog] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  setTimeout(
    () => setTriggerDialog(true),
    500,
  );
  return (
    <Formik
      initialValues={formSchema}
      validationSchema={zubaleUserFormValidationSchema}
      onSubmit={(values) => {
        onSubmit({
          workStatus: values.userInput.workStatus,
          educationLevel: values.userInput.educationLevel,
          gigworkerMotivation: values.userInput.gigworkerMotivation,
          houseIncome: values.userInput.houseIncome,
          economicDependents: values.userInput.economicDependents,
          civilStatus: values.userInput.civilStatus,
          loanPurpose: values.userInput.loanPurpose,
        });
        setSubmitOk(true);
      }}
    >
      {({
        values, handleBlur, handleChange, touched, errors, isSubmitting, handleSubmit,
      }) => (
        <AlertDialog
          dialogContent={(
            <div style={{ textAlign: 'center' }}>
              <Typography fontWeight="bold" variant="h6" gutterBottom>¡Hola Zubalero!</Typography>
              <Typography fontWeight="bold" gutterBottom>Nos gustaría saber un poco más de ti antes de continuar</Typography>

              <Form className={classes.form}>
                {isSubmitting && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' })}
                {Object.entries(zubaleFormInputs).map((zi) => (
                  <div key={zi[0]}>
                    <Typography variant="body2">{zi[1].title}</Typography>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      error={touched.userInput?.[zi[0] as keyof FormikTouched<FormSchemaV3['userInput']>]
                        && Boolean(errors.userInput?.[zi[0] as keyof FormikErrors<FormSchemaV3['userInput']>])}
                    >
                      <Select
                        id={`userInput.${zi[0]}`}
                        name={`userInput.${zi[0]}`}
                        value={values.userInput[zi[0] as keyof typeof values.userInput]}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        displayEmpty
                        renderValue={
                          values.userInput[zi[0] as keyof typeof values.userInput] !== '' ? undefined : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
                      >
                        {zi[1].inputs.map((input) => (
                          <MenuItem
                            key={input.value}
                            value={input.value}
                          >
                            {input.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.userInput?.[zi[0] as keyof FormikTouched<FormSchemaV3['userInput']>]
                          ? errors.userInput?.[zi[0] as keyof FormikErrors<FormSchemaV3['userInput']>] : ''}

                      </FormHelperText>
                    </FormControl>
                  </div>
                ))}
                <FormControlLabel
                  control={(
                    <Checkbox
                      size="small"
                      checked={checked}
                      onClick={() => setChecked((o) => !o)}
                    />
)}
                  label={(
                    <>
                      <Typography onClick={(e) => e.preventDefault()} style={{ cursor: 'default' }} display="inline" variant="body2">
                        Acepto los
                      </Typography>
                      {' '}
                      <TermsAndConditionsDialog
                        button={(
                          <Typography
                            display="inline"
                            variant="body2"
                            fontStyle="underline"
                            fontWeight="bold"
                          >
                            términos y condiciones
                          </Typography>
)}
                      />
                      {' '}
                      <Typography onClick={(e) => e.preventDefault()} style={{ cursor: 'default' }} display="inline" variant="body2">
                        de la alianza de Banca.Me con Zubale
                      </Typography>
                    </>

)}
                />
              </Form>

              <div>

                <object data={zubaleLogo} width="100px" aria-labelledby="Zubale Logo" />
              </div>
              <Typography
                variant="body2"
                color={submitOk ? 'green' : 'gray'}
              >
                {submitOk ? '¡Información guardada, clickea CERRAR para continuar!' : 'Al presionar Guardar, se actualizará tu información'}

              </Typography>
            </div>
      )}
          maxWidth="md"
          button={(
            <Button
              variant="outlined"
              style={{ fontSize: 'small' }}
            >
              Editar información
              {' '}
              <object
                data={zubaleLogoMinimal}
                style={{ borderRadius: 5, marginLeft: 10 }}
                width="16px"
                aria-labelledby="Avatar Similing"
              />
            </Button>
      )}
          action={handleSubmit}
          submitButtonDisabled={submitOk || !checked}
          hideCloseButton={!submitOk}
          onCloseAction={() => {
            setTimeout(() => setSubmitOk(false), 1000);
          }}
          isTriggered={triggerDialog}
          submitButtonLabel="Guardar"
        />
      )}
    </Formik>
  );
}

import React from 'react';
import {
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Button,
} from '@mui/material';
import { ReactSession } from 'react-client-session';
import { useHistory } from 'react-router-dom';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';
import { postClientVerifySuccess } from '../../queries/verify';
import jwtDecode from '../../utils/jwtDecode';
import useStyles from './styles';

async function verification(
  token: string,
  exp: number,
  tokenIntention: string,
  email: string,
  userId: string,
  idNumber: string,
) {
  try {
    ReactSession.setStoreType('sessionStorage');
    ReactSession.set('User', JSON.stringify({
      clientToken: token, email, idNumber,
    }));
    if (['clientLogin', 'verifyEmail'].includes(tokenIntention)) {
      return { status: 'ok', redirectURL: '/preloan' };
    }
    if (new Date(exp * 1000).getTime() < Date.now()) {
      return { status: 'expired', redirectURL: null };
    }
    switch (tokenIntention) {
      case 'resetPassword':
        await postClientVerifySuccess(userId, token);
        return { status: 'ok', redirectURL: '/account/set-password' };
      case 'verifyAccount': {
        const { data } = await postClientVerifySuccess(userId, token);
        ReactSession.set('token', data.loginToken);
        return { status: 'ok', redirectURL: '/account/login' };
      }
      case 'userReport': {
        const { data } = await postClientVerifySuccess(userId, token);
        ReactSession.set('token', data.loginToken);
        return { status: 'ok', redirectURL: '/products' };
      }
      default:
        return { status: 'error', redirectURL: '/preloan' };
    }
  } catch (err) {
    return { status: 'error', redirectURL: '/preloan' };
  }
}

type VerifyProps = {
    queryToken: string,
}

export default function Verify(props: VerifyProps) {
  const { queryToken } = props;
  const history = useHistory();
  const classes = useStyles();
  const [expired, setExpired] = React.useState(false);
  const [error, setError] = React.useState(false);

  const decodeToken = async (token: string) => {
    try {
      const {
        exp, tokenIntention, email, userId, idNumber,
      } = jwtDecode(token);
      const verif = await verification(
        token,
        exp,
        tokenIntention,
        email,
        userId,
        idNumber,
      );
      if (verif.status === 'expired') {
        return setExpired(true);
      }
      if (verif.status === 'error') {
        setError(true);
      }
      return setTimeout(() => history.push(verif.redirectURL as string), 3000);
    } catch (e) {
      setError(true);
      return setTimeout(() => history.push('/preloan'), 3000);
    }
  };

  React.useEffect(() => {
    decodeToken(queryToken);
  }, [queryToken]);
  return (
    <Grid
      item
      xs={12}
      lg={5}
      md={6}
      sm={10}
    >
      <Paper className={classes.verify} elevation={1} style={{ borderRadius: '10px' }}>
        <div>
          <object data={isotipoDark} width="40px" aria-labelledby="isotipo banca.me" />
          {expired
            ? (
              <>
                <Typography variant="h5" marginTop={10}>
                  El link ha expirado
                </Typography>
                <br />
                <Button
                  variant="contained"
                  onClick={() => history.push('/account')}
                  color="primary"
                  style={{ marginBottom: 10 }}
                >
                  Volver al inicio
                </Button>
              </>
            )
            : (
              <>
                <Typography variant="h5" fontWeight="bold" marginTop={10}>
                  Estamos verificando tu email
                </Typography>
                <br />
                <CircularProgress />
                <br />
                <Typography color={error ? 'error' : undefined}>
                  {error
                    ? 'Ha ocurrido un error, se te redirigirá al proceso de solicitud de crédito'
                    : 'Te redirigiremos en breve...'}

                </Typography>
              </>
            )}
        </div>
      </Paper>
    </Grid>
  );
}

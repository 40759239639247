import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import type { UserAccountComponent } from '../../../../types/account';
import { getUserReport } from '../../../../queries/account';
import UserReport from '../../../../feature/account/UserReport';

type UserReportParams = {
    reportId: string;
  };

export default function UserReportView(props:UserAccountComponent) {
  const { token } = props;
  const { reportId } = useParams<UserReportParams>();
  const componentRef = useRef(null);

  const {
    userReport,
    userReportError,
    userReportLoading,
  } = getUserReport(token, window.location.href.includes('demo') ? 'demo' : reportId);

  return (

    !userReportLoading && !userReportError && userReport
      ? (
        <UserReport
          URHistory={userReport.history}
          pdf={false}
          componentRef={componentRef}
          reportId={reportId}
        />
      ) : <div />

  );
}

import React from 'react';
import {
  Button,
  Fade,
  Grid,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import isotipoDark from '../../../assets/isotipo_dark_secondary.svg';
import TransferDetailsDialog from '../../../components/TransferDetailsDialog';
import CancelPrepayRequestDialog from '../CancelPrepayRequestDialog';
import ViewTitle from '../../../components/ViewTitle';
import type { PrepayRequestData } from '../../../types/account';
import useStyles from './styles';
import analytics from '../../../utils/analytics';

type FullPrepayOverviewProps = {
    token: string,
    lastPrepayRequestData: PrepayRequestData['prepayRequest']|undefined,
}

export default function FullPrepayOverview(props: FullPrepayOverviewProps) {
  const classes = useStyles();
  const {
    token,
    lastPrepayRequestData,
  } = props;
  const history = useHistory();
  const backToAccount = () => {
    history.push('/account');
  };
  const handleOpenWhatsApp = (totalToDeposit: number) => {
    analytics.track('Click Boton', { text: 'Listo, ya transferí', initiator: 'usuario' });
    window.open(
      `https://api.whatsapp.com/send/?phone=56920711980&text=¡Hola!+Hice+una+trasferencia+por+el+monto+total+del+prepago+por+$${totalToDeposit.toLocaleString('es-CL')}.`,
    );
  };
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        rowGap={5}
      >

        <Grid
          item
          xs={12}
          lg={12}
        >
          <div
            className={classes.backButton}
            onClick={backToAccount}
            aria-hidden
          >
            <ArrowBackIcon style={{ fontSize: '18px', margin: 'auto 8px' }} />
            Volver
          </div>

        </Grid>
        <ViewTitle
          title="Solicitud de Prepago total"
          color="secondary"
          variant="h4"
        />

        <Grid
          item
          xs={12}
          md={6}
          textAlign="center"
        >
          <object
            data={isotipoDark}
            aria-label="Isotipo banca.me"
            className={classes.isotipo}
          />
          {lastPrepayRequestData
              && (
              <Fade
                in
                {...({ timeout: 2500 })}
              >
                <div>
                  <div
                    className={classes.actionButtons}
                    style={{ width: '100%', marginBottom: 20 }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                    >
                      Para completar este prepago debes transferir
                    </Typography>
                    <br />
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      color="secondary"
                      gutterBottom
                    >
                      {'$ '}
                      {(+lastPrepayRequestData.prepayCommission
                        + lastPrepayRequestData.prepayPrincipal
                        + lastPrepayRequestData.interestAmount
                      ).toLocaleString('es-CL')}
                    </Typography>
                    <Typography
                      variant="body1"
                    >
                      A nuestra cuenta de Banca.Me

                    </Typography>
                  </div>
                  <TransferDetailsDialog
                    paymentMethod="dpp"
                    hasDelinquency={false}
                    unpaidDelinquencyAmount={0}
                    unpaidInstallmentAmount={0}
                  />
                  <div className={classes.actionButtons} style={{ width: '100%', margin: 0 }}>

                    <Button
                      variant="contained"
                      onClick={() => handleOpenWhatsApp(+lastPrepayRequestData.prepayCommission
                        + lastPrepayRequestData.prepayPrincipal
                        + lastPrepayRequestData.interestAmount)}
                    >
                      Listo, ya transferí
                    </Button>
                    <Button
                      onClick={backToAccount}
                      variant="contained"
                      style={{ margin: 5 }}
                    >
                      Volver a mi cuenta

                    </Button>
                  </div>
                  <CancelPrepayRequestDialog
                    prepayRequestId={lastPrepayRequestData.id}
                    token={token}
                  />
                  <div className={classes.footerInfo} style={{ width: '100%', marginTop: 10 }}>
                    <div
                      className={classes.footerIcon}
                      style={{ placeContent: 'center' }}
                    >
                      <InfoOutlinedIcon style={{ fontSize: '20px' }} />
                    </div>
                    <Typography
                      display="inline"
                      variant="body2"
                      fontWeight="light"
                      className={classes.footerText}
                    >
                      Esta solicitud dura un total del 24hs, por lo que te recomendamos
                      tener el dinero disponible para transferirnos lo antes posible.
                      Sino, puedes volver a generarla sin mayores problemas
                    </Typography>
                  </div>
                </div>
              </Fade>
              )}
        </Grid>

      </Grid>
    </div>
  );
}

import React from 'react';
import {
  Grid, Paper, Typography, Button, useTheme,
  ListItem,
  ListItemText,
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import type { ProductsData } from '../../../types/products';
import BuyProductDialog from '../BuyProductDialog';
import BackButton from '../../../components/BackButton';
import analytics from '../../../utils/analytics';
import bancameLogo from '../../../assets/bancame_logo_white.svg';

const productTypeLabels: {[key:string]:{label: string,
  icon: null|React.ReactElement, description: string}} = {
    'user-report': {
      label: 'Obtenlo por solo',
      icon: <SummarizeIcon style={{ fontSize: 30 }} />,
      description: '¡El precio más conveniente del mercado!',
    },
  };

const getProductTypeLabels = (type: string) => {
  if (Object.keys(productTypeLabels).includes(type)) {
    return productTypeLabels[type];
  }
  return { label: type, icon: null, description: null };
};

type ProductsOverviewProps = {
    token: string;
    productsData: ProductsData['data'];
}

export default function ProductsOverview(props:ProductsOverviewProps) {
  const { token, productsData } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [product] = React.useState(productsData[0]);

  const backToAccount = () => {
    history.push('/account/products');
  };
  const goToUserReportDemo = () => {
    history.push('/account/products/user-report/demo');
  };

  React.useEffect(() => {
    analytics.page('PRODUCTOS', 'HP PRODUCTOS');
  }, []);

  return (
    <div
      className={classes.contentContainer}
    >
      {token
      && (
      <BackButton
        lightMode
        label="Volver a Guia.me"
        onClickAction={backToAccount}
      />
      )}
      <Grid
        container
        columnGap={10}
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={false}
          md={6}
          sx={{ margin: 4, marginTop: { xs: 0, sm: 4 } }}
        >

          <Typography display="flex" component="div">
            <object
              data={bancameLogo}
              width="150px"
              aria-labelledby="banca.me logo"
              style={{ marginRight: 10, paddingTop: 9 }}
            />
            <Typography
              color="white.main"
              variant="h5"
              className={classes.title}
            >
              presenta
            </Typography>
          </Typography>

          <br />
          <Typography
            variant="button"
            fontWeight="bold"
            style={{
              padding: 2,
              background: theme.palette.tertiary.main,
              color: theme.palette.black.main,
            }}
          >
            Nuevo
          </Typography>
          <Typography
            variant="h2"
            color="white.main"
            fontWeight="bold"
            sx={{ marginBottom: { xs: 3, sm: 7 } }}
            marginTop={1}
          >
            Informe de deudas
          </Typography>
          <Typography
            color="white.main"
            component="div"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.2rem', sm: '2rem' } }}
          >
            Toma control de tus finanzas: descubre y elimina tus deudas por
            {' '}
            <span style={{ color: theme.palette.tertiary.main }}>
              {' '}
              {`$ ${product.amount.toLocaleString('es-CL')}`}
            </span>
          </Typography>
          <ListItem
            component="div"
            disablePadding
            sx={{ display: 'list-item', marginLeft: '16px', color: theme.palette.white.main }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: '1.15rem', color: theme.palette.white.main }}
              primary={(
                <>
                  Detalle consolidado de tus
                  {' '}
                  <b style={{ color: theme.palette.tertiary.main }}>
                    {' '}
                    deudas actuales y morosas
                  </b>
                  {', '}
                  incluyendo bancos, cooperativas, cajas de compensación, y casas comerciales
                </>
              )}
            />
          </ListItem>
          <ListItem
            component="div"
            disablePadding
            sx={{ display: 'list-item', marginLeft: '16px', color: theme.palette.white.main }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: '1.15rem', color: theme.palette.white.main }}
              primary={(
                <>
                  Conoce cómo te ven los bancos con tu
                  {' '}
                  <b style={{
                    color: theme.palette.tertiary.main,
                  }}
                  >
                    puntaje crediticio
                  </b>
                </>
)}
            />
          </ListItem>
          <ListItem
            component="div"
            disablePadding
            sx={{ display: 'list-item', marginLeft: '16px', color: theme.palette.white.main }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: '1.15rem', color: theme.palette.white.main }}
              primary={(
                <>
                  Obtén hasta un
                  {' '}
                  <b style={{
                    color: theme.palette.tertiary.main,
                  }}
                  >
                    80% de descuento
                  </b>
                  {' '}
                  sobre deudas morosas
                </>
)}
            />
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          component={Paper}
          elevation={5}
          className={classes.gridItem}
          style={{ borderRadius: 20 }}
        >
          <div style={{ maxWidth: 350, margin: '20px' }}>
            {getProductTypeLabels(product.type).icon}
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              {getProductTypeLabels(product.type).label}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: theme.palette.secondary.main }}
              fontWeight="bold"
            >
              {`$ ${product.amount.toLocaleString('es-CL')}`}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="primary"
            >
              {getProductTypeLabels(product.type).description}
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: 150 }}
                onClick={goToUserReportDemo}
              >
                Ver Ejemplo

              </Button>
              <BuyProductDialog
                token={token}
                productType={product.type}
                productId={product.id}
                amount={product.amount}
                redirectURL="/account/products"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import { Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    marginLeft: 15,
    transition: '0.5s',
    maxWidth: 200,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      cursor: 'pointer',

    },
  },
  rootLight: {
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    marginLeft: 15,
    transition: '0.5s',
    maxWidth: 150,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      cursor: 'pointer',

    },
  },
}));
export default useStyles;

export const sx = () => {
  const theme = useTheme();
  return ({
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50px',
      padding: 5,
      width: 40,
      height: 40,
      transition: '1s',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    iconButtonLight: {
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: '50px',
      padding: 5,
      width: 40,
      height: 40,
      transition: '1s',
      '&:hover': {
        backgroundColor: theme.palette.background.dark,
      },
    },
    arrow: {
      color: theme.palette.white.main,
      transition: '0.5s',
      '&:hover': {
        color: theme.palette.primary.main,
      },

    },
    arrowLight: {
      color: theme.palette.black.main,
      transition: '0.5s',
      '&:hover': {
        color: theme.palette.primary.main,
      },

    },
  });
};

import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Divider, Fade, Grid, Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomizedBreadcrumbs from '../../../components/CustomBreadcrumbs';
import type { LoanOverViewType } from '../../../types/account';
import LoanSummary from '../LoanSummary';
import LoanInstallments from '../LoanInstallments';
import LoanDetails from '../LoanDetail';
import PrepayDialog from '../PrepayDialog';
import TransferDetailsDialog from '../../../components/TransferDetailsDialog';
import useStyles from './styles';
import { productTypeLabels } from '../../../components/CreditOverview';
import InsurancesSelector from '../../../components/InsurancesSelector';

const breadCrumbsElements = [
  { label: 'Resumen', hash: 'summary', icon: undefined },
  { label: 'Cuotas', hash: 'installments', icon: undefined },
  { label: 'Detalle', hash: 'detail', icon: undefined },
];

type LoanProps = {
    loanData: LoanOverViewType,
    token: string,
    defaultExpanded: boolean
}

export default function LoanOverview(props:LoanProps) {
  const {
    loanData, token, defaultExpanded,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [unpaidDelinquencyAmount, setUnpaidDelinquencyAmount] = React.useState(0);
  const [unpaidInstallmentAmount, setUnpaidInstallmentAmount] = React.useState(0);
  const [checkedInsurances, setCheckedInsurances] = React.useState<
  {[key:string]: boolean}>({});
  React.useEffect(() => {
    if (loanData && loanData.hasDelinquency === true) {
      let delinquencyAmount = 0;
      let installmentDebtAmount = 0;
      for (let i = 0; i < loanData.unpaidDelinquencies.length; i += 1) {
        delinquencyAmount += loanData.unpaidDelinquencies[i].amount;
      }
      setUnpaidDelinquencyAmount(delinquencyAmount);
      for (let i = 0; i < loanData.installmentsDebts.length; i += 1) {
        installmentDebtAmount += loanData.installmentsDebts[i].amount;
      }
      setUnpaidInstallmentAmount(installmentDebtAmount);
    }
    if (loanData) {
      const checked: {[key:string]: boolean} = {};
      for (let i = 0; i < loanData.insurances.length; i += 1) {
        checked[loanData.insurances[i].type] = true;
      }
      setCheckedInsurances(checked);
    }
  }, [loanData]);
  return (
    <Accordion
      style={{ margin: '1%', borderRadius: 15 }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{
          borderRadius: 20,
          marginTop: 10,
          height: 120,
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            display: 'flex',
            placeContent: 'space-between',
            alignContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', columnGap: 10 }}>
            <b style={{ alignSelf: 'center' }}>
              {productTypeLabels[loanData.productType]}
            </b>
            {Object.keys(checkedInsurances).length > 0
            && (
            <InsurancesSelector
              pageCall={{ category: 'PU - TU CREDITO', name: 'VER SEGUROS' }}
              amount={loanData.loanAmount}
              periods={loanData.periods}
              handleSelectedAmountMarks={() => {}}
              handleSetCheckedInsurances={() => {}}
              showSimulator={false}
              availableInsurances={loanData.insurances}
              checkedInsurances={checkedInsurances}
              hideFooter
              hideTitle
              iconSize="small"
              readOnly
              currency={loanData.currency}
            />
            )}
          </div>

          <div style={{ alignSelf: 'center', textAlign: 'right' }}>
            <b>
              {loanData.partner.name}
            </b>
            {' '}
            <b style={{ color: theme.palette.secondary.main }}>
              [
              {loanData.paymentMethod.toUpperCase()}
              ]
            </b>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          justifyContent="flex-end"
          rowGap={5}
        >
          <Grid
            item
            xs={12}
            lg={11}
          >
            <CustomizedBreadcrumbs
              elements={breadCrumbsElements}
              separator="-"
            />
          </Grid>
          <Fade in {... ({ timeout: 2500 })}>
            <Grid
              container
              item
              xs={12}
              lg={11}
            >
              <Grid
                item
                xs={12}
                lg={11}
              >
                {window.location.hash.includes('summary')
                    && (
                    <>
                      <LoanSummary loanData={loanData} />
                      <div style={{ textAlign: 'center' }}>
                        {Object.keys(checkedInsurances).length > 0
            && (
              <>
                <Divider style={{ marginTop: 10 }} />
                <InsurancesSelector
                  pageCall={{ category: 'PU - TU CREDITO', name: 'SEGUROS PRODUCTO' }}
                  amount={loanData.loanAmount}
                  periods={loanData.periods}
                  handleSelectedAmountMarks={() => {}}
                  handleSetCheckedInsurances={() => {}}
                  showSimulator
                  availableInsurances={loanData.insurances}
                  checkedInsurances={checkedInsurances}
                  hideFooter
                  readOnly
                  currency={loanData.currency}
                />
                <Divider style={{ marginTop: 10 }} />
              </>
            )}
                      </div>
                    </>
                    )}
              </Grid>
              {window.location.hash.includes('installments')
                  && (
                  <Grid
                    item
                    xs={12}
                    lg={11}
                    xl={12}
                  >
                    <LoanInstallments loanInstallments={loanData.installments} />
                  </Grid>
                  )}
              {window.location.hash.includes('detail')
                  && (
                  <Grid
                    item
                    xs={12}
                    lg={11}
                    xl={11}
                  >
                    <LoanDetails
                      loanNumber={loanData.loan.number}
                      installmentAmount={loanData.installmentAmount}
                      lastInstallmentAmount={loanData.lastInstallmentAmount}
                      periods={loanData.periods}
                      paidQuotes={loanData.paidQuotes}
                      unpaidAmount={loanData.unpaidAmount}
                      cae={loanData.cae}
                      interestRate={loanData.interestRate}
                      toPayQuotes={loanData.toPayQuotes}
                      currency={loanData.currency}
                    />
                  </Grid>
                  )}
              <Grid
                item
                xs={12}
                lg={11}
              >
                <div className={classes.actionButtons}>
                  <TransferDetailsDialog
                    paymentMethod={loanData.paymentMethod}
                    hasDelinquency={loanData.hasDelinquency}
                    unpaidDelinquencyAmount={unpaidDelinquencyAmount}
                    unpaidInstallmentAmount={unpaidInstallmentAmount}
                  />
                  <PrepayDialog
                    loanId={loanData.loan.id}
                    unpaidAmount={loanData.unpaidAmount}
                    paidAmount={loanData.totalAmount - loanData.unpaidAmount}
                    hasDelinquency={loanData.hasDelinquency}
                    productType={loanData.productType}
                    token={token}
                  />
                </div>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import React from 'react';
import { Typography } from '@mui/material';
import ClaveUnicaFormComponent from '../../../../../../../components/ClaveUnicaForm';
import type { PostClaveUnicaApproveResponse } from '../../../../../../../types/preloan/v2';
import type { FormSchemaV3, PostClaveUnicaResponse } from '../../../../../../../types/preloan/v3';
import type { ClaveUnicaEndpointProps } from '../../../../../../../components/ClaveUnicaForm';

type ClaveUnicaRequestProps<CUResponse> = {
    formSchema: FormSchemaV3,
    postClaveUnicaApprove: (props: ClaveUnicaEndpointProps)=> Promise<
    CUResponse>
    claveUnicaSuccess: (response: CUResponse)=> void,
}

export default function ClaveUnicaRequest<CUResponse
extends PostClaveUnicaApproveResponse|PostClaveUnicaResponse
>(props:ClaveUnicaRequestProps<CUResponse>) {
  const {
    formSchema,
    postClaveUnicaApprove,
    claveUnicaSuccess,
  } = props;
  return (
    <>
      <br />
      <ClaveUnicaFormComponent<CUResponse>
        token={formSchema.PLRtoken}
        partner={formSchema.partner}
        pageCall={{ category: 'PLR - ONBOARDING', name: 'DIALOGO CU' }}
        endpoint={postClaveUnicaApprove}
        idNumber={formSchema.rut}
        origin={formSchema.origin}
        callback={claveUnicaSuccess}
        firstMessage={(
          <Typography gutterBottom>
            ¡Al ingresar tu
            {' '}
            <b>ClaveÚnica</b>
            {' '}
            podemos darte una respuesta inmediata sobre tu
            {!formSchema.partnerProducts.some((product) => product === 'mortgage') ? (
              <>
                {' '}
                aplicación para que
                {' '}
                <b>
                  puedas recibir el dinero en menos de 24 horas!
                </b>
              </>
            ) : ' evaluación!'}
            {' '}
          </Typography>
                  )}
      />
    </>
  );
}

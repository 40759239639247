import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  totalPrepayContainer: {
    width: '97.5%',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    alignContent: 'center',
    placeContent: 'center',
    borderRadius: '15px',
    display: 'grid',
    textAlign: 'left',
    gridTemplateColumns: '1fr 2fr',
    alignItems: 'center',
    paddingLeft: '5%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      width: '100%',
      textAlign: 'center',
      padding: '0',
    },
  },
}));

export default useStyles;

import React from 'react';
import { useIntercom } from 'react-use-intercom';
import analytics from '../../utils/analytics';
import BancameButton from '../BancameButton';

type AgentContactButtonProps = {
  trackCallCTA?: string,
  hide?: boolean
}

const defaultProps = {
  trackCallCTA: 'form bancame',
  hide: false,
};

export default function AgentContactButton(props: AgentContactButtonProps) {
  const {
    trackCallCTA, hide,
  } = props;
  const { show: showIntercom } = useIntercom();
  const handleOpenIntercom = () => {
    analytics.track('Contactar Agente', {
      cta: trackCallCTA,
      initiator: 'usuario',
    });
    showIntercom();
  };
  return (
    hide
      ? <div />
      : (
        <div style={{ display: 'flex', placeContent: 'center' }}>
          <BancameButton
            onClick={handleOpenIntercom}
            label="Contactar a un agente"
            type="support"
            displayFlex
          />
        </div>
      )
  );
}

AgentContactButton.defaultProps = defaultProps;

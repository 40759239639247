import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import es from 'date-fns/esm/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AgentContactButton from '../../../components/AgentContactButton';
import useStyles from './styles';
import { FormSchemaV3 } from '../../../types/preloan/v3';
import { setCapsToName } from '../../../utils/helpers';
import personalInfoValidationSchema from './validationSchema';
import analytics from '../../../utils/analytics';

const contentSwitch = (
  state: string | boolean,
  email: string,
  formSchema: FormSchemaV3,
  handlePostPersonalInfo: (personalInfo: {
    firstName: string,
    lastName: string,
    birthDate: Date | null,
    sex: string
  }) => void,
  personalInfoFormRef: React.MutableRefObject<any>,
) => {
  const theme = useTheme();
  const classes = useStyles();

  const goToAccount = () => {
    analytics.track('Click Boton', {
      initiator: 'usuario',
      text: 'Ir a mi cuenta',
    });
    setTimeout(() => {
      window.location.href = '/account/login';
    }, 1000);
  };
  const createAccount = () => {
    analytics.track('Click Boton', {
      initiator: 'usuario',
      text: 'Crear cuenta',
    });
    setTimeout(() => {
      window.location.href = '/account/register';
    }, 1000);
  };
  const scrollRef = React.useRef<null | HTMLDivElement>(null);

  switch (state) {
    case 'userHasActiveLoan':
      return {
        title: 'Crédito activo encontrado',
        content:
  <>
    Según nuestros registros
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      posees un crédito activo con nosotros,
      {' '}
    </span>

    por lo que no puedes solicitar otro hasta que termines de pagar el actual.
    Si necesitas más dinero puedes
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      solicitar un refinanciamiento con aumento
      del monto del crédito
    </span>
    {' '}
    desde tu cuenta Banca.Me.
    <div style={{ textAlign: 'center' }}>
      <div className={classes.buttonsContainer}>
        <Button onClick={goToAccount} variant="contained">Ir a mi cuenta</Button>
        <Button onClick={createAccount} variant="contained">Crear cuenta</Button>
      </div>
      <br />
      <AgentContactButton
        hide={formSchema.partner !== 'Banca.Me'}
      />
    </div>

  </>,
      };
    case 'userHasSignedLoanRequest':
      return {
        title: 'Solicitud de crédito firmada encontrada',
        content:
  <>
    Según nuestros registros
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      posees una solicitud de crédito ya firmada y vigente,
    </span>
    {' '}
    por lo que debes esperar
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      a que se acepte en nuestro sistema
    </span>
    {' '}
    Si tienes alguna inquietud, por favor ponte en contacto con nosotros
    <div style={{ textAlign: 'center', marginTop: 10 }}>
      <AgentContactButton
        hide={formSchema.partner !== 'Banca.Me'}
      />
    </div>

  </>,
      };
    case 'userHasUnsignedLoanRequest':
      return {
        title: 'Solicitud de crédito sin firmar encontrada',
        content:
  <>
    Según nuestros registros
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      posees una solicitud de crédito sin firmar y vigente,
    </span>
    {' '}
    por lo que debes continuar
    {' '}
    <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
      a partir del enlace que se envió a tu e-mail
      (
      {email}
      ).
    </span>
    {' '}
    Si tienes alguna duda, estaremos felices de ayudarte si nos escribes.
    <div style={{ textAlign: 'center', marginTop: 10 }}>
      <AgentContactButton
        hide={formSchema.partner !== 'Banca.Me'}
      />
    </div>

  </>,
      };
    case 'userHasMissingPersonalInfo':
      return {
        title: '¡Necesitamos tu ayuda!',
        content:
  <>
    <div className={classes.description}>
      <b style={{ color: theme.palette.primary.main }}>
        El rut ingresado no tiene información personal asociada,
      </b>
      {' '}
      por lo que te pedimos llenar los siguientes datos:
    </div>
    <br />
    <Formik
      innerRef={personalInfoFormRef}
      initialValues={formSchema}
      validationSchema={personalInfoValidationSchema}
      onSubmit={(values) => handlePostPersonalInfo({
        firstName: values.personalInfo?.firstName.toUpperCase().trim().replace(/\s+/g, ' '),
        lastName: values.personalInfo?.lastName.toUpperCase().trim().replace(/\s+/g, ' '),
        birthDate: values.personalInfo?.birthDate,
        sex: values.personalInfo?.sex,
      })}
    >
      {({
        values, handleBlur, handleChange, setFieldValue, setFieldTouched, touched, errors,
        isSubmitting,
      }) => (
        <Form className={classes.formContainer}>
          {isSubmitting && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' })}
          <div>
            <Typography align="left" variant="body2">Nombre(s)</Typography>
            <TextField
              size="small"
              variant="outlined"
              autoComplete="off"
              placeholder="Nombre(s)"
              id="personalInfo.firstName"
              name="personalInfo.firstName"
              error={touched.personalInfo?.firstName
                        && Boolean(errors.personalInfo?.firstName)}
              helperText={touched.personalInfo?.firstName ? errors.personalInfo?.firstName : ''}
              value={values.personalInfo.firstName}
              onChange={(e) => setFieldValue('personalInfo.firstName', setCapsToName(e.target.value))}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <Typography align="left" variant="body2">Apellido(s)</Typography>
            <TextField
              size="small"
              variant="outlined"
              autoComplete="off"
              placeholder="Apellido(s)"
              id="personalInfo.lastName"
              name="personalInfo.lastName"
              error={touched.personalInfo?.lastName
                        && Boolean(errors.personalInfo?.lastName)}
              helperText={touched.personalInfo?.lastName ? errors.personalInfo?.lastName : ''}
              value={values.personalInfo.lastName}
              onChange={(e) => setFieldValue('personalInfo.lastName', setCapsToName(e.target.value))}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <Typography align="left" variant="body2">Fecha de nacimiento</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
              <DatePicker
                disableFuture
                openTo="year"
                views={['year', 'month', 'day']}
                value={values.personalInfo.birthDate}
                onChange={(value) => setFieldValue('personalInfo.birthDate', value)}
                renderInput={(params) => (
                  <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'DD/MM/AAAA',
                    }}
                    error={touched.personalInfo?.birthDate
                              && Boolean(errors.personalInfo?.birthDate)}
                    helperText={touched.personalInfo?.birthDate ? errors.personalInfo?.birthDate : ''}
                    onBlur={(e) => { handleBlur(e); setFieldTouched('personalInfo.birthDate', true, true); }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <Typography align="left" variant="body2">Género</Typography>
            <FormControl
              ref={scrollRef}
              fullWidth
              error={touched.personalInfo?.sex
                        && Boolean(errors.personalInfo?.sex)}
            >
              <Select
                id="personalInfo.sex"
                name="personalInfo.sex"
                value={values.personalInfo.sex}
                onChange={handleChange}
                variant="outlined"
                displayEmpty
                renderValue={
                          values.personalInfo.sex !== '' ? undefined : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
              >
                <MenuItem value="F">Femenino</MenuItem>
                <MenuItem value="M">Masculino</MenuItem>
              </Select>
              <FormHelperText>{touched.personalInfo?.sex ? errors.personalInfo?.sex : ''}</FormHelperText>
            </FormControl>
          </div>
        </Form>
      )}
    </Formik>
    <br />
    <Typography variant="body2" align="center" component="div">
      {' '}
      Si tienes alguna duda, estaremos felices de ayudarte si nos escribes
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <AgentContactButton
          hide={formSchema.partner !== 'Banca.Me'}
        />
      </div>
    </Typography>

  </>,
      };
    default:
      return { title: null, content: null };
  }
};

export default contentSwitch;

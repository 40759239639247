import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/background_forms_light.svg';

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    display: 'flex',
    height: '8%',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 10),
    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      height: '7%',
      padding: theme.spacing(2, 4),
    },
  },
  logo: {
    width: '140px',
    [theme.breakpoints.down('md')]: {
      width: '100px',
    },
  },
  mobileNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileBottomNavBarAction: {
    paddingTop: '6px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  children: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.defaultSecondary,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

export default useStyles;

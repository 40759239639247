import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { prettifyRut, checkRut, formatRut } from 'react-rut-formatter';
import { ReactSession } from 'react-client-session';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  TextField,
  Grid,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  Button,
  Alert,
  Fade,
  Snackbar,
  Divider,
} from '@mui/material';
import useStyles from './styles';
import postLoginMember from './queries';
import { verifyEmail, useQuery } from '../../utils/helpers';
import analytics from '../../utils/analytics';
import bancameLogo from '../../assets/bancame_logo_dark.svg';

interface LoginInterface {
  loginType: 'rut'|'email',
  endpoint: '/login/member'|'/login/admin';
  redirects?: Object,
  fullScreen?: boolean,
  loginRedirect?: '/account'|null,
  redirectsInNewWindow?: boolean,
  givenIdNumber?: string,
  postLoginAction?: ()=> void,
}

const defaultProps = {
  redirects: {
    '/preloan': '¿Primera vez aquí? ¡Solicita un producto con nosotros!',
    '/account/register': '¿Tienes un producto y no una cuenta? ¡Haz click aquí!',
    '/account/recover-password': '¿Olvidaste tu contraseña?',
  },
  fullScreen: false,
  loginRedirect: '/account',
  redirectsInNewWindow: false,
  givenIdNumber: '',
  postLoginAction: () => {},
};

export default function Login(props:LoginInterface) {
  const {
    loginType,
    endpoint,
    redirects,
    fullScreen,
    loginRedirect = defaultProps.loginRedirect,
    redirectsInNewWindow,
    givenIdNumber,
    postLoginAction = defaultProps.postLoginAction,
  } = props;
  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();
  const [identification, setIdentificacion] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((o) => !o);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    if (givenIdNumber) {
      setIdentificacion(givenIdNumber);
    }
    analytics.page('PU - Login', 'INICIAR SESIÓN');
  }, [givenIdNumber]);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const login = async () => {
    setLoading(true);
    setWarning('');
    if (identification === '' || password === '') {
      setLoading(false);
      return setError(true);
    }
    if (identification && password) {
      try {
        if (endpoint === '/login/member') {
          const {
            token,
            identificationId,
          } = await postLoginMember(endpoint, identification, password);
          ReactSession.setStoreType('sessionStorage');
          ReactSession.set('token', token);
          analytics.identify(identificationId);
          analytics.track('Inicio sesión');
          const view = query.get('view');
          postLoginAction();
          if (loginRedirect) {
            if (view) {
              return setTimeout(() => history.push(`${loginRedirect}/${view}`), 1000);
            }
            return setTimeout(() => history.push(loginRedirect), 1000);
          }
          return undefined;
        }
        const { token } = await postLoginMember(endpoint, identification, password);
        ReactSession.setStoreType('sessionStorage');
        ReactSession.set('adminToken', token);
        postLoginAction();
      } catch (e) {
        setOpen(true);
        setLoading(false);
        if (e === 400) {
          setError(true);
          return setWarning('Usuario o contraseña inválida.');
        } if (e === 404) {
          return setWarning('Usuario inválido.');
        } if (e === 409) {
          return setWarning('No se ha verificado al mail.');
        } if (e === 428) {
          setTimeout(() => {
            window.location.href = '/account/recover-password';
          }, 3000);
          return setWarning('El usuario no tiene contraseña.');
        }
        return setWarning('Ha ocurrido un error, por favor contáctanos si esto persiste.');
      }
    }
    setLoading(false);
    return undefined;
  };
  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <Grid
        item
        xs={12}
        lg={!fullScreen && 5}
        md={!fullScreen && 7}
        sm={!fullScreen && 10}
        className={classes.paper}
        component={Paper}
        elevation={4}
        borderRadius={5}
      >
        <div>
          <div style={{ marginBottom: 10, textAlign: 'center' }}>
            <object
              data={bancameLogo}
              width="140px"
              aria-labelledby="banca.me logo"
            />
          </div>
          <Divider />
          <br />
          <Typography
            variant="h4"
            style={{ maxWidth: 500, marginInline: 'auto', marginBottom: 30 }}
          >
            Ingresa a tu cuenta
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            textAlign="left"
          >
            {loginType.toUpperCase()}
          </Typography>
          {loginType === 'rut'
            ? (
              <TextField
                style={{ marginBottom: 15 }}
                value={prettifyRut(identification)}
                placeholder="Ingresa tu RUT"
                variant="outlined"
                error={error && identification === ''}
                disabled={loading}
                helperText={(error && identification === '') ? 'Por favor ingrese su RUT' : ''}
                onChange={(e) => setIdentificacion(formatRut(e.target.value).toLowerCase())}
                onBlur={(e) => checkRut(e.target.value)}
              />
            ) : (
              <TextField
                style={{ marginBottom: 15 }}
                value={identification}
                placeholder="Ingresa tu email"
                variant="outlined"
                disabled={loading}
                error={error && identification === ''}
                helperText={(error && identification === '') ? 'Por favor ingrese su email' : ''}
                onChange={(e) => setIdentificacion(e.target.value)}
                onBlur={(e) => verifyEmail(e.target.value)}
              />
            )}
          <Typography
            gutterBottom
            variant="body1"
            textAlign="left"
          >
            Contraseña
          </Typography>
          <TextField
            style={{ marginBottom: 20 }}
            value={password}
            placeholder="Ingresa tu contraseña"
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            disabled={loading}
            variant="outlined"
            error={error && password === ''}
            helperText={(error && password === '') ? 'Por favor ingrese su contraseña' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>),
            }}
          />
          {warning
            ? (
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert severity="warning">{warning}</Alert>
              </Snackbar>
            )
            : undefined}
          <Button
            variant="contained"
            color="primary"
            onClick={login}
            disabled={loading}
          >
            INGRESAR
          </Button>
          <div style={{ width: '100%', marginTop: '4%' }}>
            {redirects !== undefined
                && Object.entries(redirects).map(([key, value]) => (
                  <Typography key={key} marginBottom="10">
                    <Link to={key} target={redirectsInNewWindow ? '_blank' : '_self'} rel="noopener" className={classes.link}>
                      {key === '/preloan' ? <b>{value}</b> : value}
                    </Link>
                  </Typography>
                ))}
          </div>
        </div>
      </Grid>
    </Fade>
  );
}

Login.defaultProps = defaultProps;

import React from 'react';
import {
  Button,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import contentSwitch from './content';
import type { PLRAlertState } from '../../../types/preloan';
import type { FormSchemaV3 } from '../../../types/preloan/v3';
import AlertDialog from '../../../components/AlertDialog';

type PLRAlertDialogProps = {
  alertState: PLRAlertState,
  email: string,
  formSchema: FormSchemaV3,
  handlePostPersonalInfo: (personalInfo: {
    firstName: string,
    lastName: string,
    birthDate: Date | null,
    sex: string
  })=> void,
}

export default function PLRAlertDialog(props: PLRAlertDialogProps) {
  const {
    alertState, email, formSchema, handlePostPersonalInfo,
  } = props;

  const personalInfoFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);

  const submitPersonalInfo = () => {
    setTimeout(() => {
      if (personalInfoFormRef.current) {
        personalInfoFormRef.current.handleSubmit();
      }
    }, 1000);
  };

  return (
    <AlertDialog
      isTriggered={!!alertState}
      keepMounted
      action={alertState === 'userHasMissingPersonalInfo' ? submitPersonalInfo : undefined}
      hideSubmitButton={alertState !== 'userHasMissingPersonalInfo'}
      submitButtonLabel={alertState === 'userHasMissingPersonalInfo' ? 'Enviar' : ''}
      dialogTitle={(
        <Typography
          variant="h6"
          fontWeight="bold"
          align="center"
          component="div"
        >
          {contentSwitch(
            alertState,
            email,
            formSchema,
            handlePostPersonalInfo,
            personalInfoFormRef,
          ).title}
        </Typography>
        )}
      dialogContent={(
        <div style={{ textAlign: 'center' }}>
          {contentSwitch(
            alertState,
            email,
            formSchema,
            handlePostPersonalInfo,
            personalInfoFormRef,
          ).content}
        </div>
      )}
      button={(
        <Button
          variant="outlined"
        >
          Ver detalle

        </Button>
)}
    />
  );
}

import React from 'react';
import {
  Button, Divider, Typography, useTheme,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AlertDialog from '../../../../../../../components/AlertDialog';
import AgentContactButton from '../../../../../../../components/AgentContactButton';
import UploadDocuments from '../../../../../../../components/UploadDocuments';
import { postUploadDocuments } from '../../../../../../../queries/preloan/v3';

type UploadPLRDocumentsProps = {
    handleUploadOk: ()=>void,
    token: string,
    buttonsDisplay?: 'block'|'grid',
    uploadDocumentsButtonVariant?: 'text' | 'contained' | 'outlined',
    needsPensionStatement: boolean,
    needsSettlement: boolean,
    resetUploadOk:boolean,
    partner: string,
    settlementsRequired?: number,
    pensionStatementsRequired?:number,

}
const defaultProps = {
  buttonsDisplay: 'grid',
  uploadDocumentsButtonVariant: 'text',
  settlementsRequired: 1,
  pensionStatementsRequired: 12,
};
export default function UploadPLRDocuments(props:UploadPLRDocumentsProps) {
  const {
    handleUploadOk,
    token,
    buttonsDisplay,
    uploadDocumentsButtonVariant,
    needsPensionStatement,
    needsSettlement,
    resetUploadOk,
    partner,
    settlementsRequired,
    pensionStatementsRequired,
  } = props;
  const theme = useTheme();
  const [disableUploadButton, setDisableUploadButton] = React.useState(true);
  const [uploadOk, setUploadOk] = React.useState(false);
  const [triggerUpload, setIstriggerUpload] = React.useState(false);
  const [triggerClose, setTriggerClose] = React.useState(false);

  const handleDocumentsOk = (documentsOk: boolean) => {
    if (!uploadOk && documentsOk) {
      return setDisableUploadButton(false);
    }
    return setDisableUploadButton(!documentsOk);
  };
  const handleTriggerUpload = () => {
    setDisableUploadButton(true);
    setIstriggerUpload(true);
  };
  const handleUploadDocumentsOk = () => {
    setUploadOk(true);
    setIstriggerUpload(false);
    setTimeout(() => {
      setTriggerClose(true);
    }, 1000);
    setTimeout(() => {
      handleUploadOk();
    }, 2000);
  };
  React.useEffect(() => {
    if (resetUploadOk) {
      setUploadOk(false);
      setTriggerClose(false);
    }
  }, [resetUploadOk]);

  const cloudIconColor = (uploadOK: boolean, variant: string) => {
    switch (variant) {
      case 'contained':
        return uploadOK
          ? theme.palette.tertiary.main : 'white';
      default:
        return uploadOK
          ? theme.palette.tertiary.main : undefined;
    }
  };

  const pageCall = () => {
    if (needsPensionStatement && needsSettlement) {
      return { category: 'PLR - DOCUMENTOS', name: 'SUBIR ULTIMA LIQUIDACIÓN Y COTIZACIONES' };
    } if (needsSettlement) {
      return { category: 'PLR - DOCUMENTOS', name: 'SUBIR ULTIMA LIQUIDACIÓN' };
    } if (needsPensionStatement) {
      return { category: 'PLR - DOCUMENTOS', name: 'SUBIR COTIZACIONES' };
    }
    return { category: 'PLR - DOCUMENTOS', name: 'SIN DOCUMENTOS A ADJUNTAR' };
  };

  return (
    <div style={{
      textAlign: 'center',
      marginTop: '5%',
      display: buttonsDisplay,
      gridTemplateColumns: '1fr 1fr',
    }}
    >

      <AlertDialog
        pageCall={pageCall()}
        button={(
          <div>
            <Button
              data-pw="preloan-upload-documents-button"
              variant={uploadDocumentsButtonVariant}
              disabled={uploadOk}
              startIcon={(
                <CloudUploadIcon style={{
                  color: cloudIconColor(uploadOk, uploadDocumentsButtonVariant as string),
                }}
                />
                  )}
            >
              Subir documentos
            </Button>
          </div>
              )}
        submitButtonLabel="Adjuntar documentos"
        submitButtonDisabled={disableUploadButton}
        disabled={uploadOk}
        dialogContent={(
          <div>
            <Typography
              gutterBottom
              align="center"
              fontWeight="bold"
              variant="h6"
            >
              {needsPensionStatement && needsSettlement && 'Subir documentos'}
              {!needsPensionStatement && needsSettlement && 'Subir última liquidación'}
              {needsPensionStatement && !needsSettlement && 'Subir cotizaciones'}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              align="left"
              style={{ maxWidth: 400, margin: 'auto' }}
            >
              Necesitamos que adjuntes estos archivos para terminar de evaluarte.
            </Typography>
            <Typography component="div" variant="caption" style={{ maxWidth: 400, margin: 'auto' }}>
              <b>
                Por favor, ten en cuenta los siguientes requisitos para una correcta
                lectura del documento:
              </b>
              <ul>
                <li>El formato debe ser PDF</li>
                <li>No puede superar los 5 mb</li>
                <li>No puede superar 2 páginas de largo</li>
                <li>
                  Revisa que el documento se pueda leer fácilmente
                  (evita subir fotos del documento o de una pantalla
                  donde aparezca el documento ya que se ve borroso.
                  Es mejor que escanees el documento o saques un pantallazo
                  y luego lo conviertas a PDF)
                </li>
              </ul>
            </Typography>

            <UploadDocuments
              getDocumentsUrl={(arg1, arg2) => postUploadDocuments(token, arg1, arg2)}
              triggerUpload={triggerUpload}
              handleDocumentsOk={handleDocumentsOk}
              handleUploadOk={handleUploadDocumentsOk}
              needsPensionStatement={needsPensionStatement}
              needsSettlement={needsSettlement}
              settlementsRequired={settlementsRequired}
              pensionStatementsRequired={pensionStatementsRequired}
            />
            <br />
            <Divider />
            <br />
            {uploadOk && (
            <Typography
              style={{ color: theme.palette.success.main, margin: 'auto' }}
              variant="caption"
              align="center"
              component="div"
            >
              ¡Hemos recibido tus documentos!
            </Typography>
            )}
          </div>
              )}
        action={handleTriggerUpload}
        triggerClose={triggerClose}
      />
      <br />
      <AgentContactButton
        hide={partner !== 'Banca.Me'}
      />
    </div>

  );
}

UploadPLRDocuments.defaultProps = defaultProps;

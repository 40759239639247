/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  Button,
  IconButton,
  InputLabel,
  Typography,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Document, Page, pdfjs } from 'react-pdf';
import { OnPasswordCallback } from 'react-pdf/dist/cjs/shared/types';
import { PasswordResponses } from 'pdfjs-dist';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import useAwaitableComponent from 'use-awaitable-component';
import useStyles from './styles';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PasswordDialog from './PasswordDialog';
import {
  createFileURL, getUploadICon, FILE_SIZE, SUPPORTED_FORMATS,
} from './helpers';
import BancameAlert from '../Alert';
import analytics from '../../utils/analytics';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const pagesToWords = {
  1: 'una página',
  2: 'dos páginas',
  3: 'tres páginas',
};

type DocumentUploadButtonProps = {
  label: string| React.ReactElement,
  file: File | undefined,
  handleSetFiles: (file: File[]|undefined) => void,
  fileOk: (state: boolean) => void,
  documentUploading: boolean,
  uploadSuccess: boolean,
  type: 'liquidacion'|'cotizaciones',
  handleSetFileInputRefs: (fileInputRefs: React.RefObject<HTMLCanvasElement>[]) => void,
  maxPages: 1 | 2 | 3,
}

export default function DocumentUploadButton(props: DocumentUploadButtonProps) {
  const {
    label,
    file,
    handleSetFiles,
    fileOk,
    documentUploading,
    uploadSuccess,
    type,
    handleSetFileInputRefs,
    maxPages,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, execute, resolve, reject, reset] = useAwaitableComponent();
  const [fileURL, setFileURL] = React.useState('');
  const [fileError, setFileError] = React.useState('');

  const [isTriggered, setIsTriggered] = React.useState(false);
  const [isUnlocked, setIsUnlocked] = React.useState(false);

  const [triggerAlert, setTriggerAlert] = React.useState(false);
  const [error, setError] = React.useState(false);

  const fileURLRef = React.useRef('');
  const isTriggeredRef = React.useRef(isTriggered);
  isTriggeredRef.current = isTriggered;

  fileURLRef.current = fileURL;

  const handleSetPdfPassword = (pass: string) => resolve(pass);

  const [nPages, setNPages] = React.useState(0);

  const inputRefs = React.useMemo(() => Array(nPages)
    .fill(null).map(() => React.createRef<HTMLCanvasElement>()), [nPages]);

  const resetFile = (event: any) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    setFileURL('');
    setNPages(0);
    handleSetFiles(undefined);
    setIsTriggered(false);
    setIsUnlocked(false);
    fileOk(false);
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNPages(numPages);
    if (isTriggered) {
      setTriggerAlert(true);
      setError(false);
      setIsUnlocked(true);
    }
    if (numPages > maxPages) {
      return setFileError(`El archivo debe tener solo ${pagesToWords[maxPages]}`);
    }
    return fileOk(true);
  }

  const handleSetFile = async (uplodadedFile: File[]|null) => {
    setFileError('');
    handleSetFiles(uplodadedFile ? [uplodadedFile[0]] : undefined);
    createFileURL(uplodadedFile
      ? uplodadedFile[0] : undefined, setFileURL);
    if (uplodadedFile && uplodadedFile[0]) {
      if (uplodadedFile[0].size > FILE_SIZE) {
        return setFileError('El archivo es muy grande (Máximo 5MB)');
      }
      if (!SUPPORTED_FORMATS.includes(uplodadedFile[0].type)) {
        return setFileError('Formatos permitidos: PDF');
      }
    }
    if (uplodadedFile && uplodadedFile[0].type !== 'application/pdf') {
      return fileOk(true);
    }
    return undefined;
  };

  const onPassword = async (
    callback: OnPasswordCallback,
    passwordResponse:number,
  ) => {
    function callbackProxy(password: string|null) {
      // Cancel button handler
      callback(password);
    }
    switch (passwordResponse) {
      case PasswordResponses.NEED_PASSWORD: {
        if (fileURLRef.current) {
          reset();
          setIsTriggered(true);
          setIsUnlocked(false);
        }
        const password = await execute();
        callbackProxy(password as string|null);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        setTriggerAlert(true);
        setError(true);
        if (fileURLRef.current) {
          reset();
          setIsTriggered(true);
          setIsUnlocked(false);
        }
        const password = await execute();
        setTriggerAlert(false);
        callbackProxy(password as string|null);
        break;
      }
      default:
    }
  };
  React.useEffect(() => {
    if (inputRefs.length > 0 && isUnlocked) {
      handleSetFileInputRefs(inputRefs);
      analytics.page('PLR - DOCUMENTOS', 'PDF DESBLOQUEADO');
    }
  }, [inputRefs, isUnlocked]);
  React.useEffect(() => {
    handleSetFile(file ? [file] : null);
  }, [file]);
  return (
    <div style={{ textAlign: 'center', marginTop: 15 }}>
      {file?.type === 'application/pdf'
      && (
      <div style={{ display: 'none' }}>
        <Document
          onPassword={onPassword}
          file={fileURL}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => setFileError('Ha ocurrido un error cargando el archivo. Por favor contáctanos')}
        >
          {Array(nPages).fill(null)
            .map((x, i) => i + 1)
            .map((page, index) => (
              <Page
                canvasRef={inputRefs[index]}
                key={page}
                pageNumber={page}
              />
            ))}
        </Document>
      </div>
      )}
      <InputLabel htmlFor={`file-${label}`}>

        <Button
          data-pw={`upload-${type}-button`}
          disabled={documentUploading || uploadSuccess}
          startIcon={uploadSuccess
            ? <CheckCircleIcon style={{ color: 'green' }} />
            : getUploadICon(
              documentUploading,
              file ? true : undefined,

              theme.palette.secondary.main,
            )}
          variant="outlined"
          component="span"
        >
          {label}
        </Button>

      </InputLabel>
      <input
        disabled={documentUploading || uploadSuccess}
        accept="application/pdf"
        className={classes.fileInput}
        id={`file-${label}`}
        type="file"
        onChange={(event) => handleSetFile(event.target.files
          ? Array.from(event.target.files) : null)}
        onClick={resetFile}
      />

      {file && (
      <a
        href={fileURL}
        target="_blank"
        rel="noreferrer"
      >
        <Typography
          component="div"
          noWrap
          style={{
            margin: 'auto',
            fontSize: 'small',
            maxWidth: '200px',
          }}
        >
          {file.name}
        </Typography>

      </a>
      )}
      <Typography
        color="error"
        component="div"
        noWrap
        style={{ margin: 'auto', fontSize: 'x-small', maxWidth: '200px' }}
      >
        {fileError}
      </Typography>
      {isTriggered
      && (
      <>
        {isUnlocked
          ? <LockOpenOutlinedIcon style={{ color: 'green' }} />
          : (
            <PasswordDialog
              isTriggered={isTriggered}
              handleSubmitPassword={handleSetPdfPassword}
              button={(
                <IconButton>
                  <LockOutlinedIcon style={{ color: 'red' }} />
                </IconButton>
        )}
            />
          )}
        <BancameAlert
          openAlert={triggerAlert}
          error={error}
          successMessage="¡PDF habilitado para lectura!"
          errorMessage="No hemos podido desbloquear el PDF, por favor inténtalo otra vez"
        />
        <Typography
          variant="caption"
          component="div"
          color={isUnlocked ? 'green' : 'red'}
          maxWidth={200}
          margin="auto"
        >
          {isUnlocked
            ? 'PDF habilitado para lectura'
            : 'PDF con contraseña. Por favor haz click en el candado para desbloquearlo.'}

        </Typography>
      </>
      )}
    </div>
  );
}

import React from 'react';
import {
  Button,
  CircularProgress,
  FormControl, MenuItem, Select, Typography,
} from '@mui/material';
import { ExperianChallengeProps } from './types';
import { experianErrorSwitch } from './helpers';
import analytics from '../../../../../../../../utils/analytics';

export default function ExperianChallengeForm(props: ExperianChallengeProps) {
  const {
    values, setFieldValue, handleSetDisableContinue, loading, error, handleSetStep,
  } = props;
  const [responsesObject, setResponsesObject] = React.useState<{[key:string]: string}>({});
  React.useEffect(() => {
    if (values.experianChallenge.data && values.experianChallenge.data.PREGUNTAS.length > 0) {
      const respObject: {[key:string]: string} = {};
      values.experianChallenge.data.PREGUNTAS.forEach((ec) => {
        respObject[ec.CODIGO_PREGUNTA] = '';
      });
      setResponsesObject(respObject);
    }
  }, [values.experianChallenge]);

  React.useEffect(() => {
    handleSetDisableContinue(Object.values(responsesObject).some((value) => value === ''));
    setFieldValue('experianChallengeResponses', responsesObject);
  }, [responsesObject]);

  const handleReset = () => {
    setFieldValue('experianRetries', values.experianRetries + 1);
    setFieldValue('experianChallenge', {
      RESUMEN: {
        RUT: '',
        USUARIO: '',
        IDCHALLENGE: '',
      },
      PREGUNTAS: [],
      codigoRetorno: '',
    });
    setFieldValue('experianChallengeResponses', {});
    handleSetStep(0);
  };

  React.useEffect(() => {
    analytics.page('PLR - SITUACIÓN LABORAL', 'CHALLENGE EXPERIAN');
  }, []);

  React.useEffect(() => {
    if (error) {
      analytics.page('PLR - SITUACIÓN LABORAL', 'ERROR INGRESO DATOS EXPERIAN', {
        errorType: values.experianChallenge.errors[0].errorCode,
        attempt: values.experianRetries,
      });
    }
  }, [error]);

  return (
    <div>
      <Typography
        gutterBottom
        align="center"
        component="div"
      >
        Antes de continuar, por favor responde las siguientes preguntas
        para verificar tu identidad
      </Typography>
      <br />
      {(Object.keys(responsesObject).length > 0)
        ? values.experianChallenge.data.PREGUNTAS
          .map((pregunta) => (
            <div key={pregunta.CODIGO_PREGUNTA} style={{ marginBlock: 10 }}>
              <Typography
                gutterBottom
                variant="body2"
              >
                {pregunta.PREGUNTA}

              </Typography>
              <FormControl
                fullWidth
              >
                <Select
                  id={pregunta.CODIGO_PREGUNTA}
                  name={pregunta.CODIGO_PREGUNTA}
                  value={responsesObject[pregunta.CODIGO_PREGUNTA]}
                  variant="outlined"
                  disabled={loading}
                  onChange={(e) => setResponsesObject({
                    ...responsesObject,
                    [pregunta.CODIGO_PREGUNTA]: e.target.value,
                  })}
                  displayEmpty
                  renderValue={
                    responsesObject[pregunta.CODIGO_PREGUNTA] !== ''
                      ? undefined
                      : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
                >
                  {(pregunta.ALTERNATIVAS)
                    .map((alternativa) => (
                      <MenuItem
                        key={`${pregunta.CODIGO_PREGUNTA}_${alternativa.CODIGO_RESPUESTA}`}
                        value={alternativa.CODIGO_RESPUESTA}
                      >
                        {alternativa.RESPUESTA}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )) : (
            <Typography
              align="center"
              fontWeight="bold"
              component="div"
            >
              <CircularProgress size={40} />
              <div style={{ margin: 30 }}>
                Cargando...
              </div>
            </Typography>
        ) }
      {error
            && (
              <>
                <Typography
                  align="center"
                  fontWeight="bold"
                  component="div"
                  variant="body2"
                  color="error"
                  gutterBottom
                >
                  {experianErrorSwitch(values.experianChallenge.errors[0].errorCode)}
                </Typography>

                {values.experianChallenge.errors
                    && values.experianChallenge.errors.find((err:{
                        errorCode: string,
                         message:string}) => ['10008', '10001'].includes(err.errorCode))
              && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                  >
                    Reintentar

                  </Button>
                </div>
              )}
              </>
            )}
    </div>
  );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  verify: {
    margin: theme.spacing(3, 15),
    padding: '5% 30px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    minHeight: '40vh',
    justifyContent: 'center',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
}));

export default useStyles;

import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';

type DataLoadingProps = {
    firstText?: string|null,
    secondText?: string|null,
    error?: boolean|undefined,
    paddingTop?: string|number
}

export default function DataLoading(props:DataLoadingProps) {
  const {
    firstText, secondText, error, paddingTop,
  } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ margin: 'auto', paddingTop }}
    >
      <Grid item justifyContent="center" alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <object data={isotipoDark} width="100px" style={{ marginInline: 'auto', marginBottom: '50px' }} aria-labelledby="Bancamin" />
          {!error && <CircularProgress size={50} />}
        </div>
        <Typography
          variant="h4"
          style={{ textAlign: 'center' }}
          fontWeight="bold"
        >
          <br />
          {!error ? firstText : '¡Ha ocurrido un error!'}
        </Typography>
        <br />
        <Typography
          variant="h5"
          style={{ textAlign: 'center' }}
        >
          {!error ? secondText : '¡Por favor contáctanos si el problema persiste!'}
        </Typography>
      </Grid>
    </Grid>
  );
}

DataLoading.defaultProps = {
  firstText: null,
  secondText: null,
  error: false,
  paddingTop: undefined,
};

import React from 'react';
import {
  Button,
  Card,
  Typography,
  useTheme,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useStyles from './styles';
import analytics from '../../../utils/analytics';

type RenegotiateDebtsProps = {
  idNumber: string,
  name: string,
  negotiableDebts: {debts: string[]},
}

export default function RenegotiateDebts(props: RenegotiateDebtsProps) {
  const {
    idNumber,
    name,
    negotiableDebts,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const action = negotiableDebts.debts.length > 0 ? 'Renegociar' : 'Repactar';
  const goToRenegotiateDebts = () => {
    if (!window.location.href.includes('demo')) {
      analytics.track('Click Boton', { text: `${action} deudas`, initiator: 'usuario' });
      window.open(`https://relif.cl/bancame-sign-mandate?rut=${idNumber}&firstName=${name}`, '_blank');
    }
  };
  return (
    <div className={classes.renegotiateDebts}>
      <Typography gutterBottom variant="h6">
        Recomendaciones
        {' '}
        <AutoAwesomeIcon fontSize="small" />
      </Typography>
      <Typography
        gutterBottom
        variant="caption"
        color="primary"
        component="div"
      >
        Lo que te propone Banca.Me

      </Typography>
      <Card
        elevation={10}
        className={classes.renegotiateDebstCard}
        style={{
          borderRadius: 20,
          textAlign: 'center',
          padding: 20,
          marginTop: '10px',
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Typography
          gutterBottom
          maxWidth={250}
          margin="auto"
          fontWeight="bold"
          color={theme.palette.white.main}
          variant="h6"
          height="10%"
        >
          Descubre si calificas a un descuento sobre tu deuda

        </Typography>
        <div style={{
          display: 'flex', alignItems: 'center', placeContent: 'center', height: '100%',
        }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={goToRenegotiateDebts}
          >
            {action}
            {' '}
            deudas
          </Button>
        </div>

      </Card>
    </div>
  );
}

import React from 'react';
import {
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import type { RefinanceGridProps, PrepaySimulationData, PrepayRequestData } from '../../../types/account';
import { prepayTypeLabels, prepayStatusLabels } from './labels';
import { postSimulatePrepayRequest, getLastPrepayRequests } from '../../../queries/account';

function RefinanceGrid(props: RefinanceGridProps) {
  const {
    unpaidAmount, paidAmount, token, hasDelinquency, productType, loanId,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [simulationError, setSimulationError] = React.useState(false);
  const [lastPrepayRequestData, setLastPrepayRequestsData] = React.useState<PrepayRequestData['prepayRequest']|undefined>(undefined);
  const [simulationData, setSimulationData] = React.useState<PrepaySimulationData>({
    delinquencyDebt: 0,
    interestAmount: 0,
    prepayComission: 0,
    totalToDeposit: 0,
    unpaidCapital: 0,
  });

  const goToTotalPrepay = () => {
    setTimeout(() => history.push({
      pathname: `/account/total-prepay/${loanId}`,
      state: {
        unpaidAmount,
        paidAmount,
        delinquencyDebt: simulationData.delinquencyDebt,
        interestAmount: simulationData.interestAmount,
        prepayComission: simulationData.prepayComission,
        totalToDeposit: simulationData.totalToDeposit,
        unpaidCapital: simulationData.unpaidCapital,
      },
    }), 500);
  };

  const goToCreditOverview = () => history.push(`/account/total-prepay/overview/${loanId}`);

  const goToPartialPrepay = () => {
    setTimeout(() => history.push(`/account/partial-prepay/${loanId}`), 500);
  };

  const goToRefinance = (type:string) => {
    setTimeout(() => history.push(`/account/refinance/${loanId}/${type}`), 500);
  };

  async function prepaySimulation() {
    try {
      const res = await postSimulatePrepayRequest(token, loanId);
      return setSimulationData(res);
    } catch (e) {
      return setSimulationError(true);
    }
  }

  const prepayDisabledText = (
    <Typography
      variant="caption"
      component="div"
      color="text.secondary"
      maxWidth={250}
      margin="5px auto"
    >
      No debes estar
      {' '}
      <b>moroso</b>
      {' '}
      para prepagar
    </Typography>
  );

  const {
    lastPrepayRequestsData,
    lastPrepayRequestDataLoading,
    lastPrepayRequestDataError,
  } = getLastPrepayRequests(token);

  React.useEffect(() => {
    if (!lastPrepayRequestData) {
      prepaySimulation();
    }
  }, [lastPrepayRequestDataLoading]);

  React.useEffect(() => {
    if (lastPrepayRequestsData.length > 0) {
      setLastPrepayRequestsData(lastPrepayRequestsData.find(
        (request) => request.prepayRequest?.prepaidLoanId === loanId,
      )?.prepayRequest);
    }
  }, [lastPrepayRequestsData]);

  return (
    <>
      <DialogTitle>
        {!lastPrepayRequestDataLoading && !lastPrepayRequestDataError
      && (
      <b>
        {lastPrepayRequestData
          ? '¡Tienes una solicitud de prepago pendiente!' : '¿Qué quieres hacer con tu crédito?'}
      </b>
      )}
        {lastPrepayRequestDataLoading && <b>Estamos consultando tus datos...</b>}
        {(lastPrepayRequestDataError || simulationError) && <Typography color="error" variant="caption" component="div">Ha ocurrido un error. Por favor contáctanos</Typography>}
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <Grid
          container
          justifyContent="center"
          style={{ textAlign: 'center', padding: '20px 30px' }}
        >
          <Grid
            item
            xs={12}
            justifyContent="center"
          >
            {!lastPrepayRequestDataLoading && !lastPrepayRequestDataError
              ? (
                <div>
                  {lastPrepayRequestData
                    ? (
                      <>
                        <div style={{ backgroundColor: 'whitesmoke', padding: 10, borderRadius: 15 }}>
                          <div className={classes.prepayDetail}>
                            <b>Tipo de solicitud: </b>
                            <Typography color="secondary" align="right" display="inline">
                              {prepayTypeLabels[lastPrepayRequestData.type]}
                            </Typography>
                          </div>
                          <div className={classes.prepayDetail}>
                            <b>Origen solicitud: </b>
                            <Typography color="secondary" align="right" display="inline">
                              {prepayStatusLabels[lastPrepayRequestData.status]}
                            </Typography>
                          </div>
                          <div className={classes.prepayDetail}>
                            <b>Total a depositar: </b>
                            <Typography color="secondary" align="right" display="inline">
                              {`$${(+lastPrepayRequestData.prepayCommission
                            + lastPrepayRequestData.prepayPrincipal
                            + lastPrepayRequestData.interestAmount
                              ).toLocaleString('es-CL')}`}
                            </Typography>
                          </div>
                          <div className={classes.prepayDetail}>
                            <b>Te ahorras: </b>
                            <Typography color="secondary" align="right" display="inline">
                              {`$${(unpaidAmount - (+lastPrepayRequestData.prepayCommission
                            + lastPrepayRequestData.prepayPrincipal
                            + lastPrepayRequestData.interestAmount
                              )).toLocaleString('es-CL')}`}
                            </Typography>
                          </div>
                        </div>
                        <br />
                        <Button
                          onClick={goToCreditOverview}
                          variant="contained"
                        >
                          Ir a solicitud
                        </Button>
                      </>
                    )

                    : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ margin: '10px auto' }}
                          disabled={hasDelinquency}
                          onClick={goToTotalPrepay}
                        >
                          Prepago todo
                        </Button>
                        {hasDelinquency && prepayDisabledText}
                        {['consumer', 'microLoan'].includes(productType)
                        && (
                        <div style={{ marginBottom: '10px' }}>
                          {!hasDelinquency && (
                            <>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                display="inline"
                              >
                                Te ahorras:
                              </Typography>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                color="secondary"
                                display="inline"
                              >
                                {' '}
                                $
                                {' '}
                                {(unpaidAmount - simulationData.totalToDeposit).toLocaleString('es-CL')}
                              </Typography>
                            </>
                          )}
                        </div>
                        )}
                        <Divider />
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '15px' }}
                            onClick={goToPartialPrepay}
                            disabled={hasDelinquency}
                          >
                            Prepago parte
                          </Button>
                          {hasDelinquency && prepayDisabledText}
                          <br />
                          {['consumer', 'microLoan'].includes(productType)
                        && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: '15px' }}
                              onClick={() => { goToRefinance('rate-period'); }}
                            >
                              Ajustar mi pago mensual
                            </Button>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: '15px' }}
                                onClick={() => { goToRefinance('cashout'); }}
                                disabled={(paidAmount / (unpaidAmount + paidAmount)) < 0.75}
                              >
                                Necesito más dinero
                              </Button>
                            </div>
                            {(paidAmount / (unpaidAmount + paidAmount)) < 0.75
                          && (
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            maxWidth={250}
                            margin="5px auto"
                          >
                            Debes pagar
                            {' '}
                            <b>al menos 75% de tu crédito</b>
                            {' '}
                            actual para solicitar más dinero
                          </Typography>
                          )}
                          </>
                        )}
                        </div>
                      </>
                    )}
                </div>
              )
              : <CircularProgress style={{ fontSize: 50 }} />}
          </Grid>

        </Grid>
      </DialogContent>
    </>
  );
}

export default RefinanceGrid;

import React from 'react';
import {
  Grid,
} from '@mui/material';
import PartialPrepay from '../../../feature/account/PartialPrepayOverview';
import useStyles from '../styles';

export default function PartialPrepayView() {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{ height: '100%' }}
      className={classes.baseBackground}
    >
      <PartialPrepay />
    </Grid>
  );
}

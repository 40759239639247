import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        marginLeft: '5%',
        marginRight: '5%',
      },
    },
  },
  buttonsContainer: {
    display: 'grid',
    marginTop: 30,
    gridTemplateColumns: '1fr 1fr',
    placeItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: 20,
    },
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
    },
  },
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    rowGap: 10,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  avatar: {
    width: 80,
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
}));

export default useStyles;

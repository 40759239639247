import React from 'react';
import {
  Card, Tooltip, Typography, useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useStyles from './styles';

type FinancialScoreProps ={
    score: number,
    pdf: boolean,
}

const financialScoreLabels = {
  healthy: {
    title: 'Estado positivo',
    description:
  <>
    Tienes una alta probabilidad de ser elegible
    para productos financieros
    (e.g., créditos de consumo, hipotecarios, etc.)
    con la banca tradicional.
  </>,
  },
  ok: {
    title: 'Estado regular',
    description:
  <>
    Presentas gastos y/o
    deudas que debes continuar pagando
    responsablemente para aumentar tus opciones
    de acceder a más y mejores productos
    financieros (e.g., créditos hipotecarios,
    aumento línea de crédito. etc.).
  </>,
  },
  critical: {
    title: 'Estado negativo',
    description:
  <>
    Presentas un nivel de deudas activas y/o
    morosas que no te permiten acceder a
    productos financieros (e.g., crédito de consumo).
    Te recomendamos renegociar tus deudas y optar
    por descuentos
    {' '}
    <span>&#128071;</span>
  </>,
  },
};
const getFinancialScoreLabels = (score:number) => {
  if (score >= 629) {
    return financialScoreLabels.healthy;
  }
  if (score < 629 && score >= 212) {
    return financialScoreLabels.ok;
  }
  return financialScoreLabels.critical;
};
export default function FinancialScore(props:FinancialScoreProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { score, pdf } = props;
  const parseScore = (sentScore:number) => {
    if (sentScore < 212) {
      return 120;
    }
    if (sentScore > 629) {
      return 768;
    }
    return score;
  };
  return (
    <div className={classes.financialScore}>
      <Typography gutterBottom variant="h6" display="flex" alignItems="center">
        Mi score financiero
        {!pdf && (
        <Tooltip
          title={(
            <div style={{ padding: 5 }}>
              <b>Mi score financiero</b>
              <div>
                Representa el puntaje que te ha dado Banca.Me analizando tus datos financieros.
                Mientras más alto el puntaje,
                mejor es tu situación para pedir un crédito con nosotros.
                ¡Si logras mejorar tu score financiero, podrás acceder a más beneficios!

              </div>
            </div>
)}
          arrow
          componentsProps={{ tooltip: { style: { background: 'white', color: theme.palette.primary.main } } }}
        >
          <InfoOutlinedIcon style={{ fontSize: '20px', marginLeft: 5 }} />
        </Tooltip>
        )}
      </Typography>
      <Typography
        gutterBottom
        variant="caption"
        color="primary"
        component="div"
      >
        Puntaje de 1 a 1000 que dice como te ven las entidades financieras
      </Typography>
      <Card style={{
        padding: 20,
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        borderRadius: 15,
      }}
      >
        <div>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight="bold"
            gutterBottom
          >
            {score}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bold"
          >
            {getFinancialScoreLabels(score).title}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            lineHeight={1.3}
            maxWidth={300}
            style={{ marginBottom: 0, fontSize: '11px' }}
          >
            {getFinancialScoreLabels(score).description}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            display: 'flex',
            paddingLeft: 5,
            paddingRight: 5,
            marginTop: -0.1223 * parseScore(score) + 102.926,
            alignSelf: 'start',
          }}
          >
            <div style={{
              width: '70px',
              background: 'black',
              color: '#fff',
              textAlign: 'center',
              padding: '2px',
              zIndex: 1,
              fontSize: '9px',
              height: 18,
              fontWeight: 'bold',
            }}
            >
              Tú estás aquí
            </div>
            <div style={{
              borderLeft: '16px solid black',
              borderBottom: '9px solid transparent',
              borderTop: '9px solid transparent',
              height: 10,
            }}
            />
          </div>
          <div>
            <div style={{
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '16px solid green',
            }}
            />
            <div style={{ background: 'green', height: 16, width: 20 }} />
            <div style={{ background: 'lightgreen', height: 16, width: 20 }} />
            <div style={{ background: 'orange', height: 16, width: 20 }} />
            <div style={{ background: 'darkorange', height: 16, width: 20 }} />
            <div style={{ background: 'red', height: 16, width: 20 }} />
            <div style={{
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '16px solid red',
            }}
            />
          </div>
          <div style={{
            fontSize: '8px', fontWeight: 'bold', marginLeft: 5,
          }}
          >
            <div style={{ marginTop: 5 }}>629</div>
            <div style={{ marginTop: 5 }}>490</div>
            <div style={{ marginTop: 5 }}>351</div>
            <div style={{ marginTop: 5 }}>212</div>
          </div>
        </div>
      </Card>
    </div>
  );
}

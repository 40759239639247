import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  infoCard: {
    padding: '5%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 2fr',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  prepaySavingsContainer: {
    backgroundColor: theme.palette.background.darkSecondary,
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      margin: '20px',
      padding: '20px',
    },
  },
  infoContainer: {
    paddingRight: '2.5%',
    [theme.breakpoints.down('md')]: {
      padding: 'auto',
      marginBottom: '5%',
    },
  },
  prepayAvatar: {
    width: '90px',
    [theme.breakpoints.down('md')]: {
      width: '70px',
    },
  },

}));

export default useStyles;

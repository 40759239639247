import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({

  paper: {
    padding: '50px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '5% 25px',
      margin: theme.spacing(3, 1),
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9em',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;

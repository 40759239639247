import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import LoginForm from '../../pages/account/login';
import RegisterForm from '../../pages/account/register';
import RecoverPasswordForm from '../../pages/account/recover-password';
import SetPasswordForm from '../../pages/account/set-password';
import VerifyEmail from '../../pages/account/verify-email';
import AccountProtectedRoutes from './protected-routes';

function AccountRoutes() {
  return (
    <Route
      render={
        ({ location }) => (
          <Switch location={location}>
            <Route exact path="/account/login" component={LoginForm} />
            <Route exact path="/account/register" component={RegisterForm} />
            <Route exact path="/account/recover-password" component={RecoverPasswordForm} />
            <Route exact path="/account/set-password" component={SetPasswordForm} />
            <Route exact path="/account/verify-email" component={VerifyEmail} />
            <AccountProtectedRoutes />
            <Route path="/">
              <Redirect to="/account" />
            </Route>
          </Switch>
        )
      }
    />
  );
}

export default AccountRoutes;

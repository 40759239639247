import React from 'react';
import { Button } from '@mui/material';
import Simulator from '../../../../../../../components/Simulator';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { AmountMarksType } from '../../../../../../../components/Simulator/types';
import AlertDialog from '../../../../../../../components/AlertDialog';
import analytics from '../../../../../../../utils/analytics';

type LoanSimulatorProps ={
    showSimulator: boolean,
    formSchema: FormSchemaV3,
    requestLoan: (params:{
        amount: number,
        interestRate: number,
        periods: number,
        installment: number,
        totalCost: number,
        monthlyCAE: number,
        CAE: number,
        originCost: number,
        taxes: number,
        insuranceCost: number,
        isInitialState: boolean
    }) => void,
      handleLoadingSimulation: (isLoading:boolean)=> void,
      archivePLR: ()=>void,
      handleShowSimulator: ()=>void,
      isSubmitting: boolean,
      amountMarks: AmountMarksType|undefined,
}

export default function LoanSimulator(props:LoanSimulatorProps) {
  const {
    showSimulator,
    formSchema,
    requestLoan,
    handleLoadingSimulation,
    archivePLR,
    handleShowSimulator,
    isSubmitting,
    amountMarks,
  } = props;
  return (
    <div style={{
      opacity: showSimulator ? 1 : 0,
      height: showSimulator ? undefined : 0,
      margin: 'auto',
      marginTop: 20,
    }}
    >
      <Simulator
        loanParams={formSchema.loanParams}
        requestLoan={requestLoan}
        isPLR
        fixAmount={+formSchema.requestedAmount}
        fixPeriod={+formSchema.requestedPeriods}
        reset={!showSimulator}
        disabledSliders={!showSimulator}
        handleLoadingSimulation={handleLoadingSimulation}
        handleRejection={archivePLR}
        offerMaxAmount={false}
        origin={formSchema.origin}
        amountMarks={amountMarks}
      />
      <AlertDialog
        dialogTitle={<div style={{ textAlign: 'center', fontWeight: 'bold' }}>¿Cerrar simulador?</div>}
        action={() => {
          analytics.track('Click Boton', {
            initiator: 'usuario',
            text: 'Cerrar Simulador',
          });
          handleShowSimulator();
        }}
        dialogContent={(
          <div style={{ textAlign: 'center' }}>
            Al cerrar el simulador no se guardarán las modificaciones realizadas
          </div>
)}
        closeAfterSubmit
        button={(
          <Button
            color="secondary"
            variant="outlined"
            disabled={isSubmitting || !showSimulator}
          >
            Cerrar simulador
          </Button>
)}
        submitButtonLabel="Continuar"
        closeButtonLabel="Volver"
      />

    </div>
  );
}

import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import accountStyles from '../styles';
import { getLastPrepayRequests } from '../../../queries/account';
import FullPrepayOverview from '../../../feature/account/FullPrepayOverview';
import { PrepayParams, PrepayRequestData, UserAccountComponent } from '../../../types/account';
import analytics from '../../../utils/analytics';

export default function FullPrepayOverviewView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;
  const {
    lastPrepayRequestsData,
    lastPrepayRequestDataLoading,
    lastPrepayRequestDataError,
  } = getLastPrepayRequests(token);
  const { loanId } = useParams<PrepayParams>();

  const [lastPrepayRequestData, setLastPrepayRequestsData] = React.useState<PrepayRequestData['prepayRequest']|undefined>(undefined);
  React.useEffect(() => {
    if (lastPrepayRequestsData.length > 0) {
      setLastPrepayRequestsData(lastPrepayRequestsData.find(
        (request) => request.prepayRequest?.prepaidLoanId === loanId,
      )?.prepayRequest);
    }
  }, [lastPrepayRequestsData]);

  React.useEffect(() => {
    analytics.page('PU - TU CREDITO', 'SOLICITUD PREPAGO TOTAL');
  }, []);

  return (
    <Grid
      container
      className={classes.baseBackground}
    >
      <FullPrepayOverview
        token={token}
        lastPrepayRequestData={lastPrepayRequestData}
      />
      {(lastPrepayRequestDataLoading || lastPrepayRequestDataError)
        && (
        <Typography
          align="center"
          variant="h4"
          fontWeight="bold"
          color={lastPrepayRequestDataError ? 'error' : undefined}
        >
          {lastPrepayRequestDataLoading && 'Cargando...'}
          {lastPrepayRequestDataError && 'Ha ocurrido un error, por favor contactáctanos'}
        </Typography>
        )}

    </Grid>
  );
}

import React from 'react';
import {
  Grid,
} from '@mui/material';
import jwtDecode from '../../../utils/jwtDecode';
import type { UserAccountComponent } from '../../../types/account';
import FintocWidget from '../../../components/FintocWidget';
import ViewTitle from '../../../components/ViewTitle';

export default function PacView(props: UserAccountComponent) {
  const { token } = props;
  const { idNumber } = jwtDecode(token);
  return (
    <>

      <ViewTitle
        title="Pagos"
        color="secondary"
        variant="h4"
        subtitle="En esta sección podrás validar que tu Pago (PAC) esta activo, o en su defecto asociar el correspondiente."
      />
      <Grid
        container
        rowGap={10}
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={5}
          margin={15}
        >

          <FintocWidget
            idNumber={idNumber}
            token={token}
          />

        </Grid>
      </Grid>
    </>
  );
}

import * as React from 'react';
import {
  Button,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import QRCode from 'qrcode.react';
import AlertDialog from '../AlertDialog';
import BancameAlert from '../Alert';
import ShareButtons from '../ShareButtons';
import analytics from '../../utils/analytics';

interface ReferralUrlDialogInterface {
  referralCode: string,
}

export default function ReferralCodeDialog(props: ReferralUrlDialogInterface) {
  const { referralCode } = props;
  const theme = useTheme();
  const url = `${`${window.location.origin}`}/preloan?origin=referral&referralCode=${referralCode}`;
  const referralShareText = '¡Hola! Te invito a postular a un cŕedito responsable con Banca.Me. Mi código de referidos es el siguiente: ';

  const copyTextToClipboard = () => {
    analytics.track('Click Boton', { text: 'Copiar', initiator: 'usuario' });
    navigator.clipboard.writeText(url);
  };

  return (
    <AlertDialog
      dialogTitle={(
        <Typography
          align="center"
          fontWeight="bold"
          component="div"
          variant="h6"
        >
          Enlace código de referidos
        </Typography>
)}
      dialogContent={(
        <>
          <Typography align="left">
            Comparte este código QR con tus conocidos o copia
            y envía el enlace para que soliciten un producto con nosotros
          </Typography>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <QRCode fgColor="#01182e" level="H" size={250} value={url} includeMargin />

            <Typography align="center" variant="caption" component="div" gutterBottom>
              {url}
            </Typography>
            <BancameAlert
              button={(
                <Tooltip
                  title="Copiar URL"
                >
                  <Button
                    onClick={copyTextToClipboard}
                    style={{
                      padding: 30,
                      display: 'flex',
                      placeItems: 'space-between',
                      width: '100%',
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    <FileCopyOutlinedIcon
                      style={{
                        color: theme.palette.grey[600],
                        fontSize: '35px',
                        paddingRight: 5,
                      }}
                    />

                    Copiar el enlace

                  </Button>
                </Tooltip>
                )}
              successMessage="Has copiado el enlace con éxito"
              errorMessage="Ha ocurrido un error copiando el texto"
              action={copyTextToClipboard}
            />
          </div>
          <Divider />
          <br />
          <Typography variant="body2" align="center">O bien, ¡Compártelo en tus redes sociales!</Typography>
          <ShareButtons
            shareText={referralShareText}
            url={url}
            twitter={false}
            telegram={false}

          />
        </>
        )}
      hideSubmitButton
      maxWidth="xs"
      buttonLabel="Generar enlace referidos"
      buttonVariant="contained"
      pageCall={{ category: 'PU - REFERIDOS', name: 'GENERAR ENLACE REFERIDOS' }}
    />
  );
}

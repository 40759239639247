import React from 'react';
import {
  Button,
} from '@mui/material';
import PrepayOptions from './prepayOptions';
import type { PrepayDialogProps } from '../../../types/account';
import AlertDialog from '../../../components/AlertDialog';

export default function PrepayDialog(props: PrepayDialogProps) {
  const {
    unpaidAmount, paidAmount, token, hasDelinquency, productType, loanId,
  } = props;
  return (
    <AlertDialog
      pageCall={{ category: 'PU - TU CREDITO', name: 'GESTIONAR' }}
      maxWidth="lg"
      hideSubmitButton
      button={productType !== 'downPayment' ? (
        <Button
          variant="contained"
          color="primary"
        >
          Gestionar
        </Button>
      ) : undefined}
      dialogContent={(
        <PrepayOptions
          unpaidAmount={unpaidAmount}
          paidAmount={paidAmount}
          hasDelinquency={hasDelinquency}
          productType={productType}
          token={token}
          loanId={loanId}
        />
)}
    />

  );
}

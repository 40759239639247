import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BackgroundPic from '../../../assets/background_isotipo_dark.svg';

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.5s',
    marginTop: 10,
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    maxWidth: '200px',
  },
  indexCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 15,
    [theme.breakpoints.down('lg')]: {
      rowGap: 10,
      gridTemplateColumns: '1fr',
      justifyContent: 'center',
    },
  },
  downloadButton: {
    marginLeft: 'auto',
  },
  financialScore: {
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
  },
  logo: {
    width: '100px',
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
  },
  avatar: {
    width: 70,
    marginBottom: 5,
  },
  renegotiateDebstCard: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'round',
    backgroundSize: '15%',
    height: 295,
  },
  renegotiateDebts: {
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '100px',
    },
  },
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: 10,
    },
  },
}));

export default useStyles;

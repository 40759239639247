import React from 'react';
import {
  Card,
  Typography,
} from '@mui/material';
import CreditProgressBar from '../CreditProgressBar';
import useStyles from './styles';
import { CreditOverviewProps } from '../../types/account';

export const productTypeLabels: {[key:string]:string} = {
  consumer: 'Consumo',
  microLoan: 'Avance',
  mortgage: 'Hipotecario',
  downPayment: 'Pie hipotecario',
};

export default function CreditOverview(props: CreditOverviewProps) {
  const {
    paidInstallments, totalInstallments, totalAmount, productType,
  } = props;
  const classes = useStyles();
  return (
    <Card
      className={classes.creditOverview}
      sx={{
        borderRadius: '15px',
      }}
    >
      <div style={{ margin: 'auto' }}>
        <Typography
          variant="h5"
          gutterBottom
          fontWeight="bold"
        >
          {productTypeLabels[productType]}
        </Typography>
        <div>
          <Typography
            variant="h6"
            color="secondary"
            display="inline"
            fontWeight="bold"
          >
            {paidInstallments}
            {' '}
            de
            {' '}
            {totalInstallments}
            {' '}
            cuotas
          </Typography>
          <Typography
            variant="h6"
            display="inline"
            fontWeight="light"
          >
            {' '}
            pagadas
          </Typography>
        </div>

      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 10fr 1fr' }}>
        <div />
        <CreditProgressBar
          paidInstallments={paidInstallments}
          totalInstallments={totalInstallments}
          totalAmount={totalAmount}
        />
        <div />
      </div>
    </Card>
  );
}

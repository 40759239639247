import * as React from 'react';
import {
  FormControl, FormHelperText, Select, Typography, MenuItem, TextField,
} from '@mui/material';
import {
  Formik, Form, FormikTouched, FormikErrors, getIn,
} from 'formik';
import { NumericFormat } from 'react-number-format';
import AlertDialog from '../../../components/AlertDialog';
import useStyles from './styles';
import type { FormSchemaV3 } from '../../../types/preloan/v3';
import validationSchema from './validationSchema';
import { formInputs, handleInputSetter } from './inputsData';
import BancameButton from '../../../components/BancameButton';

type DataFormProps = {
  formSchema: FormSchemaV3,
  onSubmit: (values: FormSchemaV3['dataForm']) => void,
  handleCloseDataForm: ()=> void;
  triggerOpen: boolean;
}

export default function DataForm(props: DataFormProps) {
  const {
    formSchema,
    onSubmit,
    handleCloseDataForm,
    triggerOpen,
  } = props;
  const classes = useStyles();
  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const [submitOk, setSubmitOk] = React.useState(false);
  const [triggerClose, setTriggerClose] = React.useState(false);
  const handleClose = () => {
    handleCloseDataForm();
  };
  return (
    <Formik
      initialValues={formSchema}
      validationSchema={() => validationSchema(Object.keys(formSchema.dataForm))}
      onSubmit={(values) => {
        setTriggerClose(true);
        setTriggerClose(false);
        return onSubmit(values.dataForm);
      }}
    >
      {({
        values,
        handleBlur,
        setFieldValue,
        handleChange,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
      }) => (
        <AlertDialog
          dialogContent={(
            <div style={{ textAlign: 'center' }}>
              <Typography
                fontWeight="bold"
                variant="h6"
                gutterBottom
              >
                Nos gustaría saber más de ti

              </Typography>
              <Form
                className={classes.form}
                style={{ gridTemplateColumns: Object.keys(values.dataForm).length === 1 ? '1fr' : undefined }}
              >
                {isSubmitting && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' })}
                {Object.entries(values.dataForm)
                  .map((zi) => (formInputs[zi[0]].inputs ? (
                    <div key={zi[0]}>
                      <Typography gutterBottom variant="body2">{formInputs[zi[0]].title}</Typography>
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        error={touched.dataForm?.[zi[0] as keyof FormikTouched<FormSchemaV3['dataForm']>]?.valueOf()
                        && Boolean(errors.dataForm?.[zi[0] as keyof FormikErrors<FormSchemaV3['dataForm']>]).valueOf()}
                      >
                        <Select
                          id={`dataForm.${zi[0]}.value`}
                          name={`dataForm.${zi[0]}.value`}
                          value={values.dataForm[zi[0] as keyof typeof values.dataForm]?.value}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          displayEmpty
                          renderValue={
                          values.dataForm[zi[0] as keyof typeof values.dataForm]?.value !== '' ? undefined : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
                        >
                          {(formInputs[zi[0]].inputs as {label:string, value: string}[])
                            .map((input) => (
                              <MenuItem
                                key={input.value}
                                value={input.value}
                              >
                                {input.label}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {getIn(touched, `dataForm.${zi[0]}.value`)
                            ? getIn(errors, `dataForm.${zi[0]}.value`) : ''}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  )
                    : (
                      <div key={zi[0]}>
                        <Typography
                          gutterBottom
                          color="text.secondary"
                          variant="body2"
                        >
                          {formInputs[zi[0]].title}

                        </Typography>
                        {zi[1]?.type === 'number'
                          ? (
                            <NumericFormat
                              id={`dataForm.${zi[0]}.value`}
                              name={`dataForm.${zi[0]}.value`}
                              variant="outlined"
                              autoComplete="off"
                              placeholder={formInputs[zi[0]].placeholder}
                              value={values.dataForm[zi[0] as keyof typeof values.dataForm].value}
                              onBlur={handleBlur}
                              onValueChange={({ value }:{
                                value: string
                              }) => setFieldValue(`dataForm.${zi[0]}.value`, value)}
                              customInput={TextField}
                              InputProps={{
                                startAdornment: formInputs[zi[0]].startAdornment,
                                endAdornment: formInputs[zi[0]].endAdornment,
                              }}
                              type="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              allowNegative={false}
                              helperText={getIn(touched, `dataForm.${zi[0]}.value`)
                                ? getIn(errors, `dataForm.${zi[0]}.value`) : ''}
                              error={getIn(touched, `dataForm.${zi[0]}.value`)
                            && Boolean(getIn(errors, `dataForm.${zi[0]}.value`))}
                            />
                          )
                          : (
                            <TextField
                              className={classes.formControl}
                              placeholder={formInputs[zi[0]].placeholder}
                              value={values.dataForm[zi[0] as keyof typeof values.dataForm].value}
                              onChange={(event) => handleInputSetter(
                                zi[1].type,
                                setFieldValue,
                                `dataForm.${zi[0]}.value`,
                                event.target.value,
                              )}
                              onBlur={handleBlur}
                              id={`dataForm.${zi[0]}.value`}
                              name={`dataForm.${zi[0]}.value`}
                              helperText={getIn(touched, `dataForm.${zi[0]}.value`)
                                ? getIn(errors, `dataForm.${zi[0]}.value`) : ''}
                              error={getIn(touched, `dataForm.${zi[0]}.value`)
                              && Boolean(getIn(errors, `dataForm.${zi[0]}.value`))}
                            />
                          )}
                      </div>
                    )))}

              </Form>
            </div>
      )}
          maxWidth="md"
          button={(
            <BancameButton
              label="Datos complementarios"
              displayFlex
              type="form"
            />
      )}
          action={handleSubmit}
          submitButtonDisabled={submitOk}
          isTriggered={triggerOpen}
          onCloseAction={() => {
            setTimeout(() => setSubmitOk(false), 1000);
            handleClose();
          }}
          submitButtonLabel="Guardar"
          triggerClose={triggerClose}
        />
      )}
    </Formik>
  );
}

import React from 'react';
import {
  Grid,
} from '@mui/material';
import accountStyles from '../styles';
import ReferralMenu from '../../../feature/account/ReferralMenu';
import { getReferralInfo } from '../../../queries/account';
import type { UserAccountComponent } from '../../../types/account';
import AccountDataLoading from '../../../components/DataLoading';

export default function ReferralsView(props: UserAccountComponent) {
  const classes = accountStyles();
  const { token } = props;
  const { referralsData, referralsDataLoading, referralsDataError } = getReferralInfo(token);

  return (
    <Grid
      container
      style={{ height: '100%' }}
    >
      {(referralsDataError || referralsDataLoading)
          && (
            <AccountDataLoading
              paddingTop="15%"
              firstText="Estamos consultando tus referidos.."
              error={referralsDataError}
            />
          )}
      {!referralsDataError && !referralsDataLoading && referralsData
          && (
          <div className={classes.contentContainer}>
            <ReferralMenu
              referralsData={referralsData}
              token={token}
            />
          </div>
          )}
    </Grid>
  );
}

import React from 'react';
import {
  Button,
  DialogContent,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { deletePrepayRequest } from '../../../queries/account';
import analytics from '../../../utils/analytics';
import AlertDialog from '../../../components/AlertDialog';

type CancelPrepayRequestDialogProps = {
  prepayRequestId: string,
  token: string
}

export default function CancelPrepayRequestDialog(props: CancelPrepayRequestDialogProps) {
  const { prepayRequestId, token } = props;
  const history = useHistory();
  const [error, setError] = React.useState(false);
  const [cancelSuccess, setCancelSuccess] = React.useState(false);

  const cancelPPR = async () => {
    try {
      await deletePrepayRequest(token, prepayRequestId);
      setCancelSuccess(true);
      analytics.track('Click Boton', { text: 'Confirmar', initiator: 'usuario' });
      setTimeout(() => history.push('/account'), 1500);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <AlertDialog
      pageCall={{ category: 'PU - TU CREDITO', name: 'CANCELAR SOLICITUD PREPAGO TOTAL' }}
      maxWidth="lg"
      action={cancelPPR}
      submitButtonLabel="Confirmar"
      button={(
        <Button
          style={{ width: 150 }}
        >
          Cancelar solicitud
        </Button>
)}
      dialogContent={(
        <DialogContent>
          <Typography
            variant="h5"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Cancelar solicitud

          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            ¿Estás seguro que deseas cancelar la solicitud?

          </Typography>
          <Typography
            style={{ margin: '3% auto', maxWidth: '350px' }}
            variant="body1"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            Puedes generar otra solicitud nuevamente cuando estimes conveniente

          </Typography>
          {cancelSuccess && <Typography variant="caption" color="green" component="div">Se ha cancelado la solicitud de prepago con éxito, volviendo a la página principal...</Typography>}
          {error && <Typography variant="caption" color="error" component="div">Ha ocurrido un error cancelando tu solicitud de prepago, por favor contáctanos</Typography>}
        </DialogContent>
)}
    />
  );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dot: {
    height: 13,
    width: 13,
    backgroundColor: theme.palette.white.main,
    borderRadius: '50%',
  },
}));

export default useStyles;

import React from 'react';
import {
  Grid,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TotalPrepayOverview from '../../../feature/account/TotalPrepayOverview';

import ViewTitle from '../../../components/ViewTitle';
import accountStyles from '../styles';
import {
  getLoan,
  postSimulatePrepayRequest,
  getLastPrepayRequests,
} from '../../../queries/account';
import type {
  UserAccountComponent, LoanOverViewType, PrepayParams, PrepayRequestData,
} from '../../../types/account';
import PrepayRequestSimulation from '../../../feature/account/PrepayRequestSimulation';
import CreatePrepayRequestDialog from '../../../feature/account/CreatePrepayRequestDialog';
import analytics from '../../../utils/analytics';

export default function TotalPrepayView(props: UserAccountComponent) {
  const classes = accountStyles();
  const history = useHistory();
  const { loanId } = useParams<PrepayParams>();
  const { token } = props;

  const [totalPrepaySimulation, setTotalPrepaySimulation] = React.useState({
    unpaidCapital: 0,
    paidAmount: 0,
    interestAmount: 0,
    delinquencyDebt: 0,
    prepayComission: 0,
    totalToDeposit: 0,
  });

  const [pprSimulationError, setSimulationError] = React.useState(false);
  const [toPrepayLoan, setToPrepayLoan] = React.useState<LoanOverViewType|undefined>(undefined);
  const [lastPrepayRequestData, setLastPrepayRequestsData] = React.useState<PrepayRequestData['prepayRequest']|undefined>(undefined);

  async function prepaySimulation(loan: LoanOverViewType) {
    try {
      const {
        delinquencyDebt, interestAmount, prepayComission, totalToDeposit,
      } = await postSimulatePrepayRequest(token, loanId);
      return setTotalPrepaySimulation({
        unpaidCapital: loan.unpaidAmount,
        paidAmount: loan.totalAmount - loan.unpaidAmount,
        delinquencyDebt,
        interestAmount,
        prepayComission,
        totalToDeposit,
      });
    } catch (e) {
      return setSimulationError(true);
    }
  }
  const backToAccount = () => {
    history.push('/account');
  };
  const {
    lastPrepayRequestsData,
    lastPrepayRequestDataLoading,
    lastPrepayRequestDataError,
  } = getLastPrepayRequests(token);

  React.useEffect(() => {
    if (lastPrepayRequestsData.length > 0) {
      setLastPrepayRequestsData(lastPrepayRequestsData.find(
        (request) => request.prepayRequest?.prepaidLoanId === loanId,
      )?.prepayRequest);
    }
  }, [lastPrepayRequestsData]);

  const { loanData, loanDataError } = getLoan(
    token,
    !lastPrepayRequestDataLoading && !lastPrepayRequestData,
  );

  if (lastPrepayRequestDataError || loanDataError) {
    backToAccount();
  }

  React.useEffect(
    () => {
      if (loanData) {
        const foundLoan = loanData.find(
          (data: LoanOverViewType) => data.loan.id === loanId,
        );
        if (foundLoan) {
          prepaySimulation(foundLoan);
          setToPrepayLoan(foundLoan);
        } else {
          history.push('/account/total-prepay/overview');
        }
      }
    },
    [loanData],
  );
  React.useEffect(
    () => {
      if (!lastPrepayRequestDataLoading && lastPrepayRequestData) {
        history.push('/account/total-prepay/overview');
      }
    },
    [lastPrepayRequestData],
  );

  React.useEffect(() => {
    analytics.page('PU - TU CREDITO', 'PREPAGO TODO');
  }, []);

  return (
    <Grid
      container
      className={classes.baseBackground}
      justifyContent="flex-end"
      rowGap={5}
    >
      <div style={{ width: '100%' }}>
        <Grid
          container
          rowGap={5}
          justifyContent="flex-end"
        >

          <Grid
            item
            xs={12}
            lg={12}
          >
            <div
              className={classes.backButton}
              onClick={backToAccount}
              aria-hidden
            >
              <ArrowBackIcon
                style={{ fontSize: '18px', margin: 'auto 8px' }}
              />
              Volver
            </div>

          </Grid>
          <ViewTitle
            title="Prepago total"
            color="secondary"
            variant="h4"
          />
          <PrepayRequestSimulation
            totalPrepaySimulation={totalPrepaySimulation}
            partner={toPrepayLoan ? toPrepayLoan.partner.name : ''}
            paymentMethod={toPrepayLoan ? toPrepayLoan.paymentMethod : ''}
            productType={toPrepayLoan ? toPrepayLoan.productType : ''}
          />
        </Grid>
      </div>

      <Grid
        item
        xs={12}
        lg={11}
        textAlign="center"
      >
        <TotalPrepayOverview
          data={[
            { id: '1', name: 'Monto ya cancelado', value: totalPrepaySimulation.paidAmount },
            { id: '2', name: 'Monto prepago', value: totalPrepaySimulation.totalToDeposit },
            { id: '3', name: 'Monto ahorrado', value: (totalPrepaySimulation.unpaidCapital - totalPrepaySimulation.totalToDeposit) },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        textAlign="center"
      >
        <CreatePrepayRequestDialog
          token={token}
          pprSimulationError={pprSimulationError}
          loanId={loanId}
        />

      </Grid>

    </Grid>
  );
}

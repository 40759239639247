import React from 'react';
import {
  FormControl, MenuItem, Select, Typography, useTheme,
} from '@mui/material';
import AlertDialog from '../../../components/AlertDialog';
import { archiveUnemployedPLR } from '../v3/PreLoanForm/formLogic';
import { FormSchemaV3 } from '../../../types/preloan/v3';
import analytics from '../../../utils/analytics';

export type WorkerType = 'DEPENDENT_PUBLIC'|'DEPENDENT_PRIVATE'|'INDEPENDENT'
const workerTypeTrackLabel = {
  DEPENDENT_PUBLIC: 'dependiente publico',
  DEPENDENT_PRIVATE: 'dependiente privado',
  INDEPENDENT: 'independiente',
  UNEMPLOYED: 'desempleado',
};
type DependentStatusDialogProps = {
    action: (workerType: WorkerType) => Promise<void>,
    isTriggered: boolean,
    formSchema: FormSchemaV3,
    handleCurrentData: (data: FormSchemaV3) => void,
    handleStep: (step: number) => void,
}

export default function DependentStatusDialog(props: DependentStatusDialogProps) {
  const {
    action,
    isTriggered,
    formSchema,
    handleCurrentData,
    handleStep,
  } = props;
  const [workerType, setWorkerType] = React.useState<WorkerType|'UNEMPLOYED'|''>('');
  const handleSetWorkerType = (wType: WorkerType|'UNEMPLOYED'|'') => {
    if (wType !== '') {
      analytics.track('Click Dropdown', {
        initiator: 'usuario',
        workerType: workerTypeTrackLabel[wType],
      });
    }
    setWorkerType(wType);
  };
  const [error, setError] = React.useState(0);
  const theme = useTheme();
  const handleAction = async () => {
    setError(0);
    if (workerType === 'UNEMPLOYED') {
      return archiveUnemployedPLR({ ...formSchema, isRedirect: 'SITUACION LABORAL' }, handleCurrentData, handleStep);
    }
    try {
      const result = await action(workerType as WorkerType);
      return result;
    } catch (e) {
      return setError(e as number);
    }
  };
  const oncloseAction = () => {
    setError(0);
    setWorkerType('');
  };
  return (
    <AlertDialog
      onCloseAction={oncloseAction}
      dialogTitle={<b>Información adicional</b>}
      dialogContent={(
        <div>
          <Typography gutterBottom>
            Por favor ayúdanos verificando tu situación laboral
            {' '}
            <span style={{ color: theme.palette.secondary.main }}>
              para continuar con tu evaluación.

            </span>
          </Typography>
          <Typography fontWeight="bold" gutterBottom>
            ¿Podrías indicarnos tu situación laboral?
          </Typography>
          <FormControl
            fullWidth
          >
            <Select
              value={workerType}
              variant="outlined"
              onChange={(e) => handleSetWorkerType(e.target.value as WorkerType|'')}
              displayEmpty
              renderValue={
                workerType !== '' ? undefined : () => <Typography color="text.secondary">Seleccionar...</Typography>
                        }
            >
              <MenuItem value="INDEPENDENT" style={{ whiteSpace: 'normal' }}>
                <span>
                  Soy trabajador
                  {' '}
                  <b>INDEPENDIENTE</b>
                  {' '}
                  (Utilizo boletas de honorarios como fuente principal de ingresos)
                </span>
              </MenuItem>
              <MenuItem value="DEPENDENT_PUBLIC" style={{ whiteSpace: 'normal' }}>
                <span>
                  Trabajo para una empresa o institución
                  {' '}
                  <b>PÚBLICA</b>
                  {' '}
                  (Municipalidad, ministerio, hospital, carabineros...)
                </span>
              </MenuItem>
              <MenuItem value="DEPENDENT_PRIVATE" style={{ whiteSpace: 'normal' }}>
                <span>
                  Trabajo para una empresa o institución
                  {' '}
                  <b>PRIVADA</b>
                  {' '}
                  (Tengo un contrato vigente y recibo un salario)
                </span>
              </MenuItem>
              <MenuItem value="UNEMPLOYED" style={{ whiteSpace: 'normal' }}>
                <span>
                  Estoy desempleado
                  o
                  no recibo un salario de manera formal
                </span>
              </MenuItem>
            </Select>
          </FormControl>
          {!!error
          && (
          <Typography color="error" variant="body2" marginTop={1} component="div">
            {[400, 422].includes(error)
              ? (
                <div>
                  <div>
                    {error === 400
                      ? 'Pareciera faltar información sobre tus boletas de honorarios.'
                      : 'Pareciera que no posees boletas de honorarios registradas.'}
                    {' '}

                    Por favor ingresa tu ClaveÚnica de nuevo luego de CERRAR esta ventana.
                    {' '}
                    <div>
                      {error === 423 ? 'Luego, escoge tu situación laboral nuevamente.' : ''}

                    </div>
                  </div>
                  <br />
                  <div>
                    Si el problema persiste, ¡Por favor, contáctanos!
                  </div>
                </div>
              )
              : 'Ha ocurrido un error. Por favor contáctanos.'}
          </Typography>
          )}
        </div>
  )}
      isTriggered={isTriggered}
      action={handleAction}
      button={<div />}
      hideCloseButton={!(!!error && [400, 423].includes(error))}
      submitButtonLabel="CONFIRMAR"
      submitButtonDisabled={!workerType || !!error}
    />
  );
}

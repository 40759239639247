import {
  makeStyles,
} from '@mui/styles';
import { Theme } from '@mui/material';

const claveUnicaDialogStyles = makeStyles((theme:Theme) => ({
  dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      margin: 0,
      backgroundColor: theme.palette.white.main,
      borderRadius: 30,
      padding: 10,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        marginLeft: '2.5%',
        marginRight: '2.5%',
      },
    },
  },
  passwordForgotten: {
    cursor: 'pointer',
    alignSelf: 'center',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  loadingAvatar: {
    animation: `$myEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  '@keyframes myEffect': {
    '0%': { transform: 'translate(0,  0px)' },
    '50%': { transform: ' translate(0, 7px)' },
    '100%': { transform: ' translate(0, -0px)' },
  },
}));

export default claveUnicaDialogStyles;

import React from 'react';
import {
  Grid, Slider, Typography, useTheme,
} from '@mui/material';
import useStyles from './styles';
import SimulatorTooltip from './SimulatorTooltip';
import {
  getOriginCost, CAECalulator,
  taxesCalculator,
  insuranceCalculator,
  compoundInsuranceRates,
} from '../../utils/installmentCalculator';

import {
  SimulatorComponentType,
  defaultProps,
  installmentMarkType,
  simulationStateType,
  AmountMarksType,
} from './types';

import { parseAmountWithCurrency } from '../../utils/helpers';

const setFixAmount = (requestedAmount: number, riskEngineAmount: number) => {
  if (requestedAmount >= riskEngineAmount) {
    return Math.round(riskEngineAmount / 1000) * 1000;
  }
  return requestedAmount;
};

export default function SimulatorComponent(props: SimulatorComponentType) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    loanParams = defaultProps.loanParams,
    isLoan,
    requestLoan = defaultProps.requestLoan,
    isPLR,
    fixAmount,
    fixPeriod,
    reset,
    disabledSliders,
    handleLoadingSimulation,
    handleRejection,
    offerMaxAmount,
    origin,
    amountMarks,
  } = props;

  const [simulatorMarks, setSimulatorMarks] = React.useState<AmountMarksType>({
    marks: {},
    maxAmount: 0,
    insuranceRates: { 0: 0 },
  });
  const [initialState, setInitialState] = React.useState({} as simulationStateType);
  const [firstTimeLoaded, setFirstTimeLoaded] = React.useState(true);
  const [state, setState] = React.useState({} as simulationStateType);
  const [triggerCalculations, setTriggerCalculations] = React.useState(false);

  React.useEffect(() => {
    if (triggerCalculations && amountMarks) {
      if (Object.keys(amountMarks.marks).length === 0) {
        return handleRejection();
      }
      setSimulatorMarks(amountMarks);

      const proposedVals12M = Object.entries(amountMarks.marks)
        .filter((mark) => mark[1].some((m) => m.value === 12));

      const getProposedVal = (
        marksEntries: [string, installmentMarkType[]][],
        proposedVals12: [string, installmentMarkType[]][],
      ) => {
        if (!offerMaxAmount && proposedVals12.length > 0) {
          return +proposedVals12[proposedVals12.length - 1][0];
        }
        return +marksEntries[marksEntries.length - 1][0];
      };

      const finalProposedVal = getProposedVal(Object.entries(amountMarks.marks), proposedVals12M);

      let initialAmount = fixAmount
        ? setFixAmount(
          +fixAmount,
          finalProposedVal,
        )
        : finalProposedVal;
      if (!amountMarks.marks[initialAmount]) {
        const amountsArray = Object.keys(amountMarks.marks);
        initialAmount = +amountsArray.reduce((prev, curr) => (
          Math.abs(+curr - initialAmount) < Math.abs(+prev - initialAmount) ? curr : prev));
      }
      const maxIntialAmountPeriod = Math.max(...(amountMarks.marks[initialAmount])
        .map((mark) => +mark.value));
      const minIntialAmountPeriod = Math.min(...(amountMarks.marks[initialAmount])
        .map((mark) => +mark.value));

      const getDefaultInitialPeriod = (maxInitialP: number, minInitialP: number) => {
        if ((maxInitialP >= 12) && (minInitialP <= 12)) {
          return 12;
        }
        if (maxInitialP < 12) {
          return maxInitialP;
        }
        return minInitialP;
      };
      const initialPeriod = ((!fixPeriod
        || ((fixPeriod as number > maxIntialAmountPeriod)
          || (fixPeriod as number < minIntialAmountPeriod)))
        ? getDefaultInitialPeriod(maxIntialAmountPeriod, minIntialAmountPeriod)
        : fixPeriod) as number;

      const matchingInstallment = amountMarks.marks[initialAmount]
        .find((mark) => mark.value === initialPeriod);
      const initialInstallment = matchingInstallment?.installment as number;
      const initialInterestRate = matchingInstallment?.interestRate as number;
      const intialMonthlyCAE = matchingInstallment?.monthlyCAE as number;

      // This function is used to divide the slider in equal parts
      const getSliderMaxPeriod = (riskEngineMaxPeriod: number) => {
        if (riskEngineMaxPeriod <= 6) {
          return riskEngineMaxPeriod;
        }
        if (riskEngineMaxPeriod <= 12) {
          return 12;
        }
        if (riskEngineMaxPeriod <= 18) {
          return 18;
        }
        if (riskEngineMaxPeriod <= 24) {
          return 24;
        }
        return riskEngineMaxPeriod;
      };

      const initialS = {
        amount: initialAmount,
        interestRate: initialInterestRate,
        period: initialPeriod,
        installment: initialInstallment,
        sliderMinPeriod: 1,
        sliderMaxPeriod: getSliderMaxPeriod(loanParams.riskEngineMaxPeriod),
        installmentMarks: amountMarks.marks[initialAmount],
        periods: initialPeriod,
        totalCost: initialInstallment * initialPeriod,
        monthlyCAE: intialMonthlyCAE,
        CAE: CAECalulator(intialMonthlyCAE),
      };
      setInitialState(initialS);
      return handleLoadingSimulation(false);
    }
    return undefined;
  }, [triggerCalculations, amountMarks]);

  React.useEffect(() => {
    handleLoadingSimulation(true);
    setTimeout(() => setTriggerCalculations(true), 1000);
  }, [loanParams]);

  React.useEffect(() => {
    if (reset) {
      setState(initialState);
    }
  }, [reset]);
  React.useEffect(() => {
    if (Object.keys(initialState).length > 0) {
      setState(initialState);
    }
  }, [initialState]);

  const changeInstallmentValue = (
    event: Event | undefined,
    value: number | number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activeThumb: number | undefined,
    loanAmount: number,
  ) => {
    const marks = simulatorMarks.marks[loanAmount];
    const periodIndex = (marks.map((x:installmentMarkType) => (x.value)).indexOf(value as number));
    const matchMarksWithPayProb = Object.keys(loanParams.payProb).length - marks.length;
    const thisPeriods = Object.keys(
      loanParams.payProb,
    )[periodIndex + matchMarksWithPayProb] as string;

    const matchingInstallment = marks.find((inst:installmentMarkType) => inst.value === +value);
    const thisInstallment = matchingInstallment?.installment as number;
    const newMonthlyCAE = matchingInstallment?.monthlyCAE as number;
    const newInterestRate = matchingInstallment?.interestRate as number;

    setState({
      ...state,
      interestRate: newInterestRate,
      installment: thisInstallment,
      period: +value,
      periods: +thisPeriods,
      totalCost: Math.round((thisInstallment) * +thisPeriods * 10 ** loanParams.currency.precision)
      / 10 ** loanParams.currency.precision,
      monthlyCAE: newMonthlyCAE,
      CAE: CAECalulator(newMonthlyCAE),
      amount: loanAmount,
      installmentMarks: marks,
    });
  };
  const changeAmountValue = (
    event: Event,
    amount: number | number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activeThumb: number,
  ) => {
    const marks = simulatorMarks.marks[+amount];
    const allowedMarks = marks.filter((mark) => (mark.value >= state.sliderMinPeriod)
    && (mark.value <= state.sliderMaxPeriod));
    const installmentPeriod = allowedMarks[0].value;
    changeInstallmentValue(undefined, installmentPeriod, undefined, +amount);
  };

  const productName = () => {
    if (loanParams.riskEngineMaxPeriod < 2) {
      return 'adelanto';
    }
    return 'crédito';
  };

  React.useEffect(() => {
    if (isPLR && Object.keys(state).length > 0) {
      const isInitialState = JSON.stringify(state) === JSON.stringify(initialState)
      && firstTimeLoaded;
      requestLoan({
        amount: state.amount,
        interestRate: state.interestRate,
        periods: state.periods,
        installment: state.installment,
        totalCost: state.totalCost,
        monthlyCAE: state.monthlyCAE,
        CAE: state.CAE,
        originCost: getOriginCost(+state.amount, loanParams.productType, loanParams.currency),
        taxes: taxesCalculator({
          loanAmount: +state.amount,
          originCost: getOriginCost(+state.amount, loanParams.productType, loanParams.currency),
          periods: state.periods,
          insuranceCost: insuranceCalculator({
            amount: +state.amount,
            periods: state.periods,
            insuranceRate: compoundInsuranceRates(simulatorMarks.insuranceRates, state.periods),
            precision: loanParams.currency.precision,
          }),
          productType: loanParams.productType as 'consumer'|'microLoan'|'mortgage'|'downPayment',
          interestRate: state.interestRate,
          currency: loanParams.currency,
        }),
        insuranceCost: insuranceCalculator({
          amount: +state.amount,
          periods: +state.periods,
          insuranceRate: compoundInsuranceRates(simulatorMarks.insuranceRates, state.periods),
          precision: loanParams.currency.precision,
        }),
        isInitialState,
      });
      setFirstTimeLoaded(false);
    }
  }, [state]);

  return (
    <div className={classes.simulator}>
      {Object.keys(state).length > 0
        ? (
          <>
            <div style={{
              backgroundColor: theme.palette.background.dark,
              paddingBlock: 10,
              borderRadius: '20px 20px 0 0',
            }}
            >
              <Typography
                style={{ color: theme.palette.white.main }}
                align="center"
                variant="subtitle1"
                gutterBottom
              >
                <b>
                  Simula tu
                  {' '}
                  {productName()}
                </b>
              </Typography>
              <Typography
                fontWeight="bold"
                align="center"
                variant="caption"
                component="div"
                style={{ color: theme.palette.white.main, margin: '5px auto' }}
              >
                {loanParams.basePaymentMethod === 'pac' ? 'Forma de cobro: Pago automático con cuenta bancaria' : 'Forma de cobro: Descuento por planilla'}
              </Typography>
            </div>
            <div style={{
              paddingBlock: 20,
              borderRadius: '0 0 20px 20px',
              border: `6px solid ${theme.palette.gray.main}`,
              borderTop: 0,
            }}
            >
              <Typography
                align="center"
                fontWeight="bold"
                variant="body1"
                component="div"
                gutterBottom
              >
                ¿Cuánto quieres pedir?
              </Typography>

              <Typography
                color="text.secondary"
                align="center"
                variant="h6"
              >
                <b style={{ fontSize: '1.3em' }}>
                  {parseAmountWithCurrency(loanParams.currency, state.amount, false, true)}
                </b>
              </Typography>
              {origin === 'brincus'
            && (
            <Typography align="center" variant="caption" component="h3" style={{ fontStyle: 'italic', opacity: 0.8 }}>
              equivalente a
              <b>
                {' '}
                {state.amount / loanParams.amountStepSize}
                {' '}
                plan
                {state.amount / loanParams.amountStepSize > 1 ? 'es' : ''}
                {' '}
                de colegio online
                {' '}
              </b>
              de Brincus
            </Typography>
            )}
              <div className={classes.slider_padding}>
                <Slider
                  aria-labelledby="discrete-slider"
                  step={loanParams.amountStepSize}
                  value={state.amount}
                  min={loanParams.minAmount}
                  max={simulatorMarks.maxAmount}
                  onChange={changeAmountValue}
                  disabled={disabledSliders || origin === 'brincus'}
                />
              </div>
              <Typography
                fontWeight="bold"
                align="center"
                variant="body1"
                component="h3"
                gutterBottom
              >
                ¿Valor cuota mensual que puedes pagar? *
              </Typography>
              <Typography align="center" variant="h6">
                <b style={{ color: theme.palette.secondary.main, fontSize: '1.3em' }}>
                  {parseAmountWithCurrency(loanParams.currency, +(state.installmentMarks
                    .find(
                      (inst: installmentMarkType) => inst.value === state.period,
                    )?.installment || 0))}

                </b>
              </Typography>
              <Typography align="center" variant="caption" component="h3" style={{ fontStyle: 'italic', opacity: 0.8 }}>
                por
                <b>
                  {' '}
                  {state.periods}
                  {' '}
                  {`mes${state.periods > 1 ? 'es' : ''}`}
                </b>
              </Typography>
              <div className={classes.slider_padding}>
                <Slider
                  aria-labelledby="discrete-slider"
                  step={null}
                  value={state.period}
                  min={state.sliderMinPeriod}
                  max={state.sliderMaxPeriod}
                  marks={state.installmentMarks}
                  onChange={(event) => {
                    changeInstallmentValue(
                      undefined,
                      +(event.target as HTMLInputElement).value,
                      undefined,
                      state.amount,
                    );
                  }}
                  disabled={disabledSliders}
                />
              </div>
            </div>

            <Typography
              align="center"
              variant="caption"
              component="h3"
              style={{ fontStyle: 'italic', marginTop: 5 }}
              gutterBottom
            >
              *Estimación de acuerdo a tu capacidad de pago
            </Typography>

            {isLoan && !!state.totalCost && !!state.interestRate && !!state.CAE && (
            <Grid container>
              <Grid item xs={6} style={{ cursor: 'default' }}>
                <Typography align="center" variant="subtitle2" component="h4">
                  <span style={{}}>Plazo:</span>
                </Typography>
                <Typography align="center" variant="body1">
                  {state.periods}
                  {' '}
                  meses
                </Typography>
              </Grid>
              <SimulatorTooltip
                amount={state.amount}
                originCost={getOriginCost(
                  state.amount,
                  loanParams.productType,

                  loanParams.currency,
                )}
                totalCost={state.totalCost}
                taxes={taxesCalculator(
                  {
                    loanAmount: state.amount,
                    originCost: getOriginCost(
                      state.amount,
                      loanParams.productType,
                      loanParams.currency,
                    ),
                    periods: state.periods,
                    insuranceCost: insuranceCalculator({
                      amount: state.amount,
                      periods: state.periods,
                      insuranceRate: compoundInsuranceRates(
                        simulatorMarks.insuranceRates,
                        state.periods,
                      ),
                      precision: loanParams.currency.precision,
                    }),
                    productType: loanParams.productType as 'consumer'|'microLoan'|'mortgage'|'downPayment',
                    interestRate: state.interestRate,
                    currency: loanParams.currency,
                  },
                )}
              >
                <Grid item xs={6} style={{ cursor: 'default' }}>
                  <Typography align="center" variant="subtitle2" component="h4">
                    <span style={{ borderBottom: '1px dashed' }}>
                      Costo total
                      {' '}
                      {productName()}
                      :

                    </span>
                  </Typography>
                  <Typography align="center" variant="body1">
                    {state.totalCost.toLocaleString('es-CL')}
                  </Typography>
                </Grid>
              </SimulatorTooltip>
              <Grid item xs={6} style={{ cursor: 'default' }}>
                <Typography align="center" variant="subtitle2" component="h4">
                  <span style={{}}>Tasa de interes mensual:</span>
                </Typography>
                <Typography align="center" variant="body1">
                  {(state.interestRate / 12).toLocaleString('es-CL', { style: 'percent', maximumFractionDigits: 3 })}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ cursor: 'default' }}>
                <Typography align="center" variant="subtitle2" component="h4">
                  <span style={{}}>CAE:</span>
                </Typography>
                <Typography align="center" variant="body1">
                  {state.CAE.toLocaleString('es-CL', { style: 'percent', maximumFractionDigits: 3 })}
                </Typography>
              </Grid>
            </Grid>
            )}
          </>
        ) : <div>Cargando...</div>}
    </div>

  );
}

SimulatorComponent.defaultProps = defaultProps;

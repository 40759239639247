import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/background_forms_dark_secondary.svg';

const paymentStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BackgroundPic})`,
    backgroundSize: 'cover  ',
    backgroundPosition: 'center',
  },
}));

export default paymentStyles;

import React from 'react';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';
import useStyles from './styles';

export default function LoadingAvatar(props: { loading: boolean }) {
  const { loading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loadingTextVariant, setLoadingTextVariant] = React.useState(true);
  React.useEffect(() => {
    if (loading) {
      const interval = setInterval(() => setLoadingTextVariant((o) => !o), 10000);
      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [loading]);
  return (
    <div style={{ textAlign: 'center' }}>
      <object
        className={classes.loadingAvatar}
        data={isotipoDark}
        width="30px"
        aria-labelledby="banca.me isotipo"
      />
      <Typography
        variant="body1"
        color="success"
        component="div"
        fontStyle="italic"
        width={250}
        height={50}
        style={{
          marginBlock: 10, display: 'flex', alignItems: 'center', placeContent: 'center',
        }}
      >
        {' '}
        {loadingTextVariant ? 'Estamos consultando tus datos...' : 'Este proceso puede durar unos minutos...'}
      </Typography>
      <CircularProgress
        style={{
          color: loadingTextVariant ? undefined : theme.palette.secondary.main,
          transition: '5s',
          animationDuration: '5s',
        }}
        size={25}
      />
    </div>
  );
}

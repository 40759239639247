import { Button, useTheme } from '@mui/material';
import React from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import bancameLogo from '../../assets/isotipo_dark.svg';

type BancameButtonProps = {
    onClick?: () => void,
    label: string,
    displayFlex?: boolean,
    type?: 'default'|'form'|'upload'|'support'
}

const defaultProps = {
  type: 'default',
};

const buttonLogoSwitch = (type: BancameButtonProps['type']) => {
  const theme = useTheme();
  switch (type) {
    case 'form':
      return <AppRegistrationIcon style={{ color: theme.palette.primary.main }} />;
    case 'upload':
      return <CloudUploadIcon style={{ color: theme.palette.primary.main, marginBottom: '4px' }} />;
    case 'support':
      return <ContactSupportIcon style={{ color: theme.palette.primary.main, marginBottom: '4px' }} />;
    default:
      return (
        <object
          data={bancameLogo}
          aria-labelledby="Banca.Me logo"
          height="20px"
          style={{ marginRight: 10 }}
        />
      );
  }
};

export default function BancameButton(props:BancameButtonProps) {
  const {
    onClick, label, displayFlex, type,
  } = props;
  return (
    <Button
      variant="outlined"
      style={displayFlex ? { display: 'flex', justifyContent: 'space-between', alignSelf: 'center' } : {}}
      onClick={onClick}
    >
      {buttonLogoSwitch(type)}
      <div>
        {label}
      </div>
      <div />
    </Button>
  );
}

BancameButton.defaultProps = {
  onClick: () => {},
  displayFlex: true,
};

BancameButton.defaultProps = defaultProps;

import { useLocation } from 'react-router-dom';
import { prettifyRut } from 'react-rut-formatter';
import { ReactSession } from 'react-client-session';
import { Currency } from '../types/account';

export const MailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(undefined)$/;
export const regionNumsAreaCodes = ['58', '57', '55', '52', '51', '53', '32', '34', '33', '35', '72', '75', '71', '73', '41', '42', '43', '45', '63', '64', '65', '67', '61'];
export const santiagoAndMobileAreaCodes = ['9', '2'];

export const verifyEmail = (email: string) => {
  if (MailRegex.test(email)) {
    return true;
  }
  return false;
};
export const setCapsToName = (str:string) => {
  if (str.length > 1) {
    const nomArray = str.split(' ');
    const upperCaseArray:any = [];
    nomArray.forEach((nom: string) => {
      let name = '';
      if (nom !== '') {
        name = nom.toLowerCase();
        name = name[0].toUpperCase() + name.slice(1);
      }
      upperCaseArray.push(name);
    });
    return upperCaseArray.join(' ');
  }
  return str;
};
export function checkPassword(passw:string) {
  if (!passw) {
    return true;
  }
  const paswd = /^(?=.*[0-9])(?=.*[!@#$%^&.,/?|\\[()<>/?-_=+~`;:'"{}*])[a-zA-Z0-9!@#$%^&.,/?|()<>/?-_=+~`;:'"{}*]{8,15}$/;
  return !paswd.test(passw);
}
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function checkDaysThreshold(dateToCompare: Date, threshold: number) {
  const diff = Math.abs(new Date().getTime()
  - dateToCompare.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays < threshold;
}

export const setRut = (setFieldValue: (fieldName:string, val: string)=> void, value: string) => {
  const formatted = prettifyRut(value);
  setFieldValue('rut', formatted);
};

export const verifyPhone = (
  value: string,
  setFieldValue: (fieldName: string, val: string) => void,
  fieldName: string,
) => {
  const regex = /^[0-9]+$/;
  if (value.slice(0, 4) === '+56 '
        && regex.test(value.slice(1, value.length).replace(' ', ''))) {
    setFieldValue(fieldName, `${value}`);
  }
};

export const verifyText = (
  value: string,
  setFieldValue: (fieldName: string, val: string) => void,
  fieldName: string,
) => {
  const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ -]+$/;
  if (value === '' || regex.test(value)) {
    setFieldValue(fieldName, value);
  }
};

export const parseCurrencyAbbreviation = (currency: Currency) => {
  switch (currency.abbreviation) {
    case 'CLF':
      return 'UF';
    default:
      return '$';
  }
};
export function parseAmountWithCurrency(
  currency: Currency,
  amount: number,
  hideAbbreviation: boolean = false,
  truncate: boolean = false,
) {
  switch (currency.abbreviation) {
    case 'CLF':
      return `${hideAbbreviation ? '' : parseCurrencyAbbreviation(currency)} ${amount.toLocaleString(
        'es-cl',
        {
          minimumFractionDigits: truncate ? 0 : currency.precision,
          maximumFractionDigits: truncate ? 0 : currency.precision,
        },
      )}`;
    default:
      return `${hideAbbreviation ? '' : parseCurrencyAbbreviation(currency)}  ${amount.toLocaleString(
        'es-cl',
      )}`;
  }
}

export const getElementFromStorage = (element:string) => {
  ReactSession.setStoreType('sessionStorage');
  return ReactSession.get(element);
};
